// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Default styles for all screen sizes */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  /* colors */
  --clr-white: #ffffff;
  --clr-dark: #000000;

  /* sizes */
  --size-xxs: 0.5rem;
  --size-xs: 0.75rem;
  --size-xs2: 0.65rem;
  --size-sm: 0.875rem;
  --size-base: 1rem;
  --size-lg: 1.125rem;
  --size-xl: 1.25rem;
  --size-2xl: 1.5rem;
  --size-3xl: 1.875rem;
  --size-4xl: 2.25rem;
  --size-5xl: 3rem;
  --size-5-5xl: 3.5rem;
  --size-6xl: 3.75rem;
  --size-7xl: 4.5rem;
  --size-8xl: 6rem;
  --size-9xl: 8rem;
  --size-10xl: 10rem;
}

/* Media query for screens smaller than 768px (e.g., tablets and mobile devices) */
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA,wCAAwC;AACxC;EACE,SAAS;EACT;;cAEY;EACZ,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE;aACW;AACb;;AAEA;EACE,WAAW;EACX,oBAAoB;EACpB,mBAAmB;;EAEnB,UAAU;EACV,kBAAkB;EAClB,kBAAkB;EAClB,mBAAmB;EACnB,mBAAmB;EACnB,iBAAiB;EACjB,mBAAmB;EACnB,kBAAkB;EAClB,kBAAkB;EAClB,oBAAoB;EACpB,mBAAmB;EACnB,gBAAgB;EAChB,oBAAoB;EACpB,mBAAmB;EACnB,kBAAkB;EAClB,gBAAgB;EAChB,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA,kFAAkF","sourcesContent":["/* Default styles for all screen sizes */\nbody {\n  margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\",\n    \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\",\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, \"Courier New\",\n    monospace;\n}\n\n:root {\n  /* colors */\n  --clr-white: #ffffff;\n  --clr-dark: #000000;\n\n  /* sizes */\n  --size-xxs: 0.5rem;\n  --size-xs: 0.75rem;\n  --size-xs2: 0.65rem;\n  --size-sm: 0.875rem;\n  --size-base: 1rem;\n  --size-lg: 1.125rem;\n  --size-xl: 1.25rem;\n  --size-2xl: 1.5rem;\n  --size-3xl: 1.875rem;\n  --size-4xl: 2.25rem;\n  --size-5xl: 3rem;\n  --size-5-5xl: 3.5rem;\n  --size-6xl: 3.75rem;\n  --size-7xl: 4.5rem;\n  --size-8xl: 6rem;\n  --size-9xl: 8rem;\n  --size-10xl: 10rem;\n}\n\n/* Media query for screens smaller than 768px (e.g., tablets and mobile devices) */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
