import { useEffect, useState } from "react";
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { styled } from "@mui/material/styles";
import Avatar1 from "../../Images/Avatars/avatar1.png";
import Avatar2 from "../../Images/Avatars/avatar2.png";
import Avatar3 from "../../Images/Avatars/avatar3.png";
import Avatar4 from "../../Images/Avatars/avatar4.png";
import styles from "./AlertCards.module.css";
import { getPriorityInfo, PriorityIcon } from "../ColorIcons/ColorIcons";
import { handleUpdateAnomaly } from "../../services/alert.service";

const StyledSelect = styled(Select)(({ theme }) => ({
  "& .MuiSelect-select": {
    padding: "0px",
    fontSize: "16px",
    fontWeight: "500",
    lineHeight: "24px",
    display: "flex",
    alignItems: "center",
    minHeight: "24px",
    "&:focus": {
      backgroundColor: "transparent",
    },
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
  "& .MuiSvgIcon-root": {
    right: "4px",
    fontSize: "24px",
  },
}));

function GroupAvatars() {
  return (
    <AvatarGroup max={4} sx={{ justifyContent: "start", marginTop: "3rem" }}>
      <Avatar sx={{ width: 28, height: 28 }} alt="avatar1" src={Avatar1} />
      <Avatar sx={{ width: 28, height: 28 }} alt="avatar2" src={Avatar2} />
      <Avatar sx={{ width: 28, height: 28 }} alt="avatar3" src={Avatar3} />
      <Avatar sx={{ width: 28, height: 28 }} alt="avatar4" src={Avatar4} />
    </AvatarGroup>
  );
}

function formatDate(dateString) {
  const date = new Date(dateString.split("/").reverse().join("-"));
  const day = date.getDate();
  const month = date.toLocaleString("default", { month: "long" });
  const year = date.getFullYear();

  const getOrdinalSuffix = (day) => {
    if (day > 3 && day < 21) return "th";
    switch (day % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  return `${day}${getOrdinalSuffix(day)} ${month} ${year}`;
}

function AlertCards({
  Date,
  ID,
  Priority,
  ImgSrc,
  Title,
  Status,
  Description,
  onClick,
  fetchAlerts,
}) {
  const [status, setStatus] = useState(Status);
  const formattedDate = formatDate(Date);

  useEffect(() => {
    setStatus(Status);
  }, [Status]);

  const handleStatusChange = async (event) => {
    const newStatus = event.target.value;
    setStatus(newStatus);
    await handleUpdateAnomaly(ID, "anomaly", newStatus === "Resolved");
    fetchAlerts();
  };

  const handleImageClick = (event) => {
    event.stopPropagation();
    onClick();
  };

  return (
    <div
      className={`${styles.AlertCards} ${
        status === "Active" ? styles.activeAlert : ""
      }`}
    >
      <div className={styles.displayIMGContainer}>
        <div className={styles.dateContainer}>
          <p>{formattedDate}</p>
          <p>Case Id- {ID}</p>
        </div>
        <div
          className={styles.priorityContainer}
          style={{
            backgroundColor: getPriorityInfo(Priority).backgroundColor,
          }}
        >
          <PriorityIcon priority={Priority} />
        </div>
        <img
          src={`data:image/png;base64,${ImgSrc}`}
          alt="alert-img"
          onClick={handleImageClick}
          style={{ cursor: "pointer" }}
        />
      </div>
      <div className={styles.titleContainer}>
        <h5>{Title}</h5>
        <StyledSelect
          value={status}
          onChange={handleStatusChange}
          sx={{
            color: status === "Active" ? "#FF384A" : "#4AB58E",
            backgroundColor: "transparent",
            width: "110px",
          }}
        >
          <MenuItem value="Active" sx={{ color: "#FF384A", fontSize: "16px" }}>
            Active
          </MenuItem>
          <MenuItem
            value="Resolved"
            sx={{ color: "#4AB58E", fontSize: "16px" }}
          >
            Resolved
          </MenuItem>
        </StyledSelect>
      </div>
      <p>{Description.charAt(0).toUpperCase() + Description.slice(1)}</p>
      <GroupAvatars />
    </div>
  );
}

export default AlertCards;
