import { LineChart } from "@mui/x-charts/LineChart";
import { axisClasses } from "@mui/x-charts/ChartsAxis";
import styles from "./CameraFeedGraph.module.css";

const Data = [480, 500, 550, 630, 600, 650, 660];
const xLabels = [0, 2, 4, 6, 8, 10, 12];
const yLabels = [0, 240, 360, 480, 720, 1080];

function CameraFeedGraph({ Title }) {
  return (
    <div className={styles.CameraFeedGraph}>
      <h2>{Title}</h2>
      <LineChart
        height={300}
        series={[{ data: Data, area: true, color: "#B692F6", showMark: false }]}
        xAxis={[
          {
            scaleType: "point",
            data: xLabels,
            label: "Month",
            labelStyle: {
              fill: "#667085",
            },
          },
        ]}
        yAxis={[
          {
            data: yLabels,
            min: 0,
            max: 1080,
            label: "Resolution and Frame",
            labelStyle: {
              fill: "#667085",
            },
            ticks: yLabels,
            valueFormatter: (value) => `${value}p`,
          },
        ]}
        grid={{
          horizontal: true,
          stroke: "#F2F4F7",
        }}
        leftAxis={{
          disableLine: true,
          disableTicks: true,
        }}
        bottomAxis={{
          disableLine: true,
          disableTicks: true,
        }}
        margin={{ left: 60, right: 0 }}
        sx={{
          ".MuiAreaElement-root": {
            fill: "url(#gradient)",
          },
          [`.${axisClasses.left} .${axisClasses.label}`]: {
            transform: "translate(-20px, 0)",
          },
          [`.${axisClasses.root}`]: {
            [`.${axisClasses.tickLabel}`]: {
              fill: "#667085",
            },
          },
        }}
      />
      <svg width="0" height="0">
        <defs>
          <linearGradient id="gradient" x1="0" y1="0" x2="0" y2="1">
            <stop offset="0%" stopColor="#B692F6" stopOpacity={1} />
            <stop offset="100%" stopColor="#B692F6" stopOpacity={0} />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
}

export default CameraFeedGraph;
