import React, { useEffect, useState } from "react";
import styles from "./EmployeeInteractions.module.css";
import { BarChart } from "@mui/x-charts/BarChart";
import { axisClasses } from "@mui/x-charts/ChartsAxis";
import { useSelector } from "react-redux";
import {
  MenuItem,
  Select,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { styled } from "@mui/system";
import { handleFetchEmployeeInteractions } from "../../services/analysis.service";

const StyledSelect = styled(Select)(() => ({
  "& .MuiSelect-select": {
    padding: "0px",
    fontSize: "16px",
    fontWeight: "500",
    lineHeight: "24px",
    display: "flex",
    alignItems: "center",
    minHeight: "24px",
    "&:focus": {
      backgroundColor: "transparent",
    },
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
  "& .MuiSvgIcon-root": {
    right: "4px",
    fontSize: "24px",
  },
}));

const CustomButton = styled(Button)(({ theme, selected }) => ({
  color: "var(--clr-dark)",
  border: "1px solid #e0e0e0",
  borderRadius: ".75rem",
  fontSize: "var(--size-3xl)",
  textTransform: "capitalize",
  fontWeight: "500",
  padding: ".5rem 1rem",
  "&:hover": {
    backgroundColor: "#e0e0e0",
  },
  ...(selected && {
    backgroundColor: "var(--clr-dark)",
    color: "var(--clr-white)",
  }),
}));

function EmployeeInteractions() {
  const employeeInteractions = useSelector(
    (state) => state.analysis.employeeInteractions
  );
  const areas = useSelector((state) => state.analysis.areas);
  const error = useSelector((state) => state.analysis.error);
  const [selectedArea, setSelectedArea] = useState(areas[0] ? areas[0] : "602");
  const [selectDays, setSelectDays] = useState(7);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);

  const handleAreaChange = (event) => {
    setSelectedArea(event.target.value);
  };

  const handleFilterChange = (event) => {
    setSelectDays(event.target.value);
  };

  useEffect(() => {
    handleFetchEmployeeInteractions(selectedArea, selectDays);
  }, [selectedArea, selectDays]);

  const handleBarClick = (event, labelInfo) => {
    const employeeNames = Object.keys(employeeInteractions);
    const clickedEmployeeName = employeeNames[labelInfo.dataIndex];
    if (clickedEmployeeName) {
      const clickedEmployee = employeeInteractions[clickedEmployeeName];
      setSelectedEmployee({
        name: clickedEmployeeName,
        ...clickedEmployee,
      });
      setDialogOpen(true);
    }
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setSelectedEmployee(null);
  };

  const formatDate = (timestamp) => {
    return new Date(timestamp).toLocaleString();
  };

  const EmployeeActivityDistributionChart = ({ data }) => {
    if (!data || Object.keys(data).length === 0) return null;

    const employeeNames = Object.keys(data);
    const totalTimeSpent = employeeNames.map(
      (name) => data[name].total_time_spent
    );

    const secondsToHours = (seconds) => seconds / 3600;

    const valueFormatter = (value) => `${value.toFixed(2)} hr`;

    return (
      <BarChart
        height={400}
        series={[
          {
            data: totalTimeSpent.map((time) => secondsToHours(time)),
            valueFormatter,
          },
        ]}
        xAxis={[
          {
            data: employeeNames,
            scaleType: "band",
            label: "Employee ID",
            labelStyle: {
              fill: "#667085",
            },
          },
        ]}
        yAxis={[
          {
            label: "Time (in hours)",
            valueFormatter: (value) => `${value.toFixed(0)}hr`,
            labelStyle: {
              fill: "#667085",
            },
          },
        ]}
        slotProps={{
          noDataOverlay: { message: "No data to display in this chart" },

          bar: ({ id }) =>
            id === "totalTime"
              ? {
                  clipPath: `inset(0px round 12px 12px 0px 0px)`,
                }
              : {},
          legend: {
            direction: "row",
            position: { vertical: "top", horizontal: "right" },
            itemMarkWidth: 8,
            itemMarkHeight: 8,
            itemGap: 20,
            labelStyle: {
              fontSize: 12,
              fill: "#667085",
            },
          },
        }}
        borderRadius={10}
        grid={{
          horizontal: true,
          stroke: "#F2F4F7",
        }}
        leftAxis={{
          disableLine: true,
          disableTicks: true,
        }}
        bottomAxis={{
          disableLine: true,
          disableTicks: true,
        }}
        onItemClick={handleBarClick}
        margin={{ left: 60, right: 0, bottom: 50 }}
        sx={{
          [`.${axisClasses.left} .${axisClasses.label}`]: {
            transform: "translate(-20px, 0)",
          },
          [`.${axisClasses.bottom} .${axisClasses.label}`]: {
            transform: "translate(0, 10px)",
          },
          [`.${axisClasses.root}`]: {
            [`.${axisClasses.tickLabel}`]: {
              fill: "#667085",
            },
          },
        }}
      />
    );
  };

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className={styles.AvgEmployeeIdealTime}>
      <div className={styles.optionContainer}>
        <h4>Employee Interactions</h4>
        <div className={styles.chartFilter}>
          <div className={styles.areaDropdown}>
            <StyledSelect
              sx={{ color: "#a3aed0" }}
              value={selectedArea}
              onChange={handleAreaChange}
            >
              {areas.map((area) => (
                <MenuItem key={area} value={area} sx={{ fontSize: "16px" }}>
                  {area}
                </MenuItem>
              ))}
            </StyledSelect>
          </div>
          <div className={styles.areaDropdown}>
            <StyledSelect
              sx={{ color: "#a3aed0" }}
              value={selectDays}
              onChange={handleFilterChange}
            >
              <MenuItem value={30} sx={{ fontSize: "16px" }}>
                Monthly
              </MenuItem>
              <MenuItem value={7} sx={{ fontSize: "16px" }}>
                Weekly
              </MenuItem>
              <MenuItem value={1} sx={{ fontSize: "16px" }}>
                Daily
              </MenuItem>
            </StyledSelect>
          </div>
        </div>
      </div>
      {Object.keys(employeeInteractions).length > 0 ? (
        <EmployeeActivityDistributionChart data={employeeInteractions} />
      ) : (
        <p>No data available</p>
      )}

      <Dialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        PaperProps={{
          sx: {
            borderRadius: "2rem",
            padding: "1rem 1.5rem",
            backgroundColor: "var(--clr-white)",
            color: "var(--clr-dark)",
            width: "600px",
            maxHeight: "80vh",
            overflow: "auto",
          },
        }}
      >
        <DialogTitle
          sx={{
            padding: "1rem 0.625rem",
            fontSize: "var(--size-5xl)",
            fontWeight: "500",
          }}
        >
          Employee Details
        </DialogTitle>
        <DialogContent sx={{ padding: "1rem 0.625rem 0.625rem 0.625rem" }}>
          {selectedEmployee ? (
            <>
              <div className={styles.dialogBox}>
                <p>Name: {selectedEmployee.name}</p>
                <p>
                  Total Time Spent:{" "}
                  {selectedEmployee.total_time_spent.toFixed(2)} seconds
                </p>
              </div>
              <h3
                style={{
                  paddingTop: "3rem",
                  fontSize: "var(--size-5xl)",
                  fontWeight: "500",
                }}
              >
                Interactions:
              </h3>
              {selectedEmployee.interactions.length > 0 ? (
                selectedEmployee.interactions.map((interaction, index) => (
                  <div key={index} className={styles.dialogBox}>
                    <p>Interacted with: {interaction.person2}</p>
                    <p>Employee ID: {interaction.employee_id2}</p>
                    <p>
                      Time Spent: {interaction.time_spent.toFixed(2)} seconds
                    </p>
                    <p>Timestamp: {formatDate(interaction.timestamp)}</p>
                  </div>
                ))
              ) : (
                <div className={styles.dialogBox}>
                  <p>No interactions found for this employee</p>
                </div>
              )}
            </>
          ) : (
            <div className={styles.dialogBox}>
              <p>No employee selected</p>
            </div>
          )}
        </DialogContent>
        <DialogActions sx={{ padding: "1rem 0.625rem" }}>
          <CustomButton onClick={handleCloseDialog}>Close</CustomButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default EmployeeInteractions;
