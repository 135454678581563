import { BarPlot } from "@mui/x-charts/BarChart";
import { LinePlot, MarkPlot } from "@mui/x-charts/LineChart";
import { ResponsiveChartContainer } from "@mui/x-charts/ResponsiveChartContainer";
import { ChartsGrid } from "@mui/x-charts/ChartsGrid";
import { ChartsXAxis } from "@mui/x-charts/ChartsXAxis";
import { ChartsYAxis } from "@mui/x-charts/ChartsYAxis";
import { ChartsTooltip } from "@mui/x-charts/ChartsTooltip";
import { ChartsAxisHighlight } from "@mui/x-charts/ChartsAxisHighlight";
import { axisClasses } from "@mui/x-charts/ChartsAxis";
import styles from "./AvgResponsebyHourGraph.module.css";
import { ChartsLegend } from "@mui/x-charts/ChartsLegend";
import { useSelector } from "react-redux";

const daysOfWeek = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

function AvgResponsebyHourGraph() {
  const avgResponseByHour = useSelector(
    (state) => state.analysis.avgResponseByHour
  );
  const loading = useSelector((state) => state.analysis.loading);
  const error = useSelector((state) => state.analysis.error);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  const chartData = {
    responseTimes: daysOfWeek.map(
      (day) => avgResponseByHour[day]?.response_time || 0
    ),
    ticketCounts: daysOfWeek.map(
      (day) => avgResponseByHour[day]?.ticket_count || 0
    ),
  };

  const series = [
    {
      type: "bar",
      yAxisId: "response",
      label: "Response Time",
      color: "#D6BBFB",
      data: chartData.responseTimes,
    },
    {
      type: "line",
      curve: "linear",
      yAxisId: "ticket",
      color: "#16458A",
      label: "Ticket Count",
      data: chartData.ticketCounts,
    },
  ];

  return (
    <div className={styles.AvgResponsebyHourGraph} style={{ width: "100%" }}>
      <h4>Avg First Response by hour of the week</h4>
      <ResponsiveChartContainer
        series={series}
        height={400}
        xAxis={[
          {
            id: "days",
            data: daysOfWeek,
            scaleType: "band",
            labelStyle: {
              fill: "#667085",
            },
            disableLine: true,
            disableTicks: true,
          },
        ]}
        yAxis={[
          {
            id: "response",
            scaleType: "linear",
            label: "Response Time (hours)",
            labelStyle: {
              fill: "#667085",
            },
            disableLine: true,
            disableTicks: true,
            valueFormatter: (value) => `${value}hr`,
          },
          {
            id: "ticket",
            scaleType: "linear",
            label: "Ticket Count",
            labelStyle: {
              fill: "#667085",
            },
            disableLine: true,
            disableTicks: true,
          },
        ]}
        margin={{ left: 60, right: 60 }}
        sx={{
          [`.${axisClasses.root}`]: {
            [`.${axisClasses.tickLabel}`]: {
              fill: "#667085",
            },
          },
        }}
      >
        <ChartsAxisHighlight x="line" />
        <BarPlot
          borderRadius={10}
          slotProps={{
            bar: { clipPath: `inset(0px round 12px 12px 0px 0px)` },
          }}
        />
        <LinePlot />
        <MarkPlot />
        <ChartsGrid horizontal={true} classes={{ stroke: "#F2F4F7" }} />
        <ChartsLegend
          slotProps={{
            legend: {
              direction: "row",
              position: { vertical: "top", horizontal: "right" },
              itemMarkWidth: 8,
              itemMarkHeight: 8,
              labelStyle: {
                fontSize: 12,
                fill: "#667085",
              },
            },
          }}
        />
        <ChartsXAxis
          label="Day of the Week"
          position="bottom"
          axisId="days"
          tickLabelStyle={{
            fontSize: 10,
          }}
        />
        <ChartsYAxis
          label="Response Time (hours)"
          position="left"
          axisId="response"
          tickLabelStyle={{ fontSize: 10 }}
          sx={{
            [`& .${axisClasses.label}`]: {
              transform: "translateX(-15px)",
            },
          }}
        />
        <ChartsYAxis
          label="Ticket Count"
          position="right"
          axisId="ticket"
          tickLabelStyle={{ fontSize: 10 }}
          sx={{
            [`& .${axisClasses.label}`]: {
              transform: "translateX(20px)",
            },
          }}
        />
        <ChartsTooltip />
      </ResponsiveChartContainer>
    </div>
  );
}

export default AvgResponsebyHourGraph;
