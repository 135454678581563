// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.InfoCards_InfoCards__EXwuL {
  background-color: #ffffff;
  box-shadow: 0px 2px 12px 0px #536fcb40;
  border-radius: 20px;
  display: flex;
  padding: 15px;
  gap: 20px;
  min-width: -moz-fit-content;
  min-width: fit-content;
  width: 100%;
}

.InfoCards_dataContainer__6k0tF {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.InfoCards_ImgContainer__fTw\\+J {
  border-radius: 5rem;
  background-color: #f4f7fe;
  padding: 20px;
  align-content: center;
}

.InfoCards_dataContainer__6k0tF h4,
.InfoCards_dataContainer__6k0tF p {
  margin: 0;
  text-align: left;
}

.InfoCards_dataContainer__6k0tF h4 {
  color: #a3aed0;
  font-size: var(--size-3xl);
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.02em;
}

.InfoCards_dataContainer__6k0tF p {
  color: #2b3674;
  font-size: var(--size-5xl);
  font-weight: 600;
  line-height: 32px;
  letter-spacing: -0.02em;
}
`, "",{"version":3,"sources":["webpack://./src/Components/InfoCards/InfoCards.module.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,sCAAsC;EACtC,mBAAmB;EACnB,aAAa;EACb,aAAa;EACb,SAAS;EACT,2BAAsB;EAAtB,sBAAsB;EACtB,WAAW;AACb;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;AACzB;;AAEA;EACE,mBAAmB;EACnB,yBAAyB;EACzB,aAAa;EACb,qBAAqB;AACvB;;AAEA;;EAEE,SAAS;EACT,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,0BAA0B;EAC1B,gBAAgB;EAChB,iBAAiB;EACjB,uBAAuB;AACzB;;AAEA;EACE,cAAc;EACd,0BAA0B;EAC1B,gBAAgB;EAChB,iBAAiB;EACjB,uBAAuB;AACzB","sourcesContent":[".InfoCards {\n  background-color: #ffffff;\n  box-shadow: 0px 2px 12px 0px #536fcb40;\n  border-radius: 20px;\n  display: flex;\n  padding: 15px;\n  gap: 20px;\n  min-width: fit-content;\n  width: 100%;\n}\n\n.dataContainer {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n}\n\n.ImgContainer {\n  border-radius: 5rem;\n  background-color: #f4f7fe;\n  padding: 20px;\n  align-content: center;\n}\n\n.dataContainer h4,\n.dataContainer p {\n  margin: 0;\n  text-align: left;\n}\n\n.dataContainer h4 {\n  color: #a3aed0;\n  font-size: var(--size-3xl);\n  font-weight: 500;\n  line-height: 24px;\n  letter-spacing: -0.02em;\n}\n\n.dataContainer p {\n  color: #2b3674;\n  font-size: var(--size-5xl);\n  font-weight: 600;\n  line-height: 32px;\n  letter-spacing: -0.02em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"InfoCards": `InfoCards_InfoCards__EXwuL`,
	"dataContainer": `InfoCards_dataContainer__6k0tF`,
	"ImgContainer": `InfoCards_ImgContainer__fTw+J`
};
export default ___CSS_LOADER_EXPORT___;
