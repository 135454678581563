import styles from "./ThermalGraph.module.css";
import thermalIMG from "../../Images/heatMap.png";

function ThermalGraph({ Title }) {
  return (
    <div className={styles.ThermalGraph}>
      <h2>{Title}</h2>
      <img src={thermalIMG} alt="thermal-image" />
    </div>
  );
}

export default ThermalGraph;
