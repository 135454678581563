import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Divider } from "@mui/material";
import styles from "./alertDetail.module.css";
import {
  handleFetchIncidentDetails,
  handleUpdateAnomaly,
} from "../../../services/alert.service";
import backArrow from "../../../icon/leftArrow.svg";
import dotIcon from "../../../icon/dot-icon.svg";
import toolIcon from "../../../icon/tool-icon.svg";
import monitorIcon from "../../../icon/monitor-icon.svg";
import acceptIcon from "../../../icon/tick.svg";
import cancelIcon from "../../../icon/cross.png";

function AlertDetail({ alert, onBack }) {
  const { incidentDetails, loading, error } = useSelector(
    (state) => state.alert
  );

  useEffect(() => {
    handleFetchIncidentDetails(alert.case_id, alert.value_type);
  }, [alert.case_id, alert.value_type]);

  const handleUpdateAnomalyClick = async () => {
    await handleUpdateAnomaly(alert.case_id, "anomaly", "true");
    onBack();
  };

  if (loading || !incidentDetails) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className={styles.alertDetail}>
      <div className={styles.backButton}>
        <button onClick={onBack}>
          <img src={backArrow} alt="back" />
          <div>
            <p>Alerts</p>
            <img src={dotIcon} alt="dot" />
            <p style={{ fontWeight: "bold" }}>Details</p>
          </div>
        </button>
      </div>
      <div className={styles.alertContainer}>
        <div className={styles.leftContainer}>
          <img
            src={`data:image/png;base64,${incidentDetails.image}`}
            alt="alert-detail-img"
          />
          <div className={styles.about}>
            <h2>{incidentDetails.content}</h2>
            <p>{incidentDetails.detailedContent}</p>
          </div>
        </div>
        <div className={styles.rightContainer}>
          <div>
            <h6>Date and Time</h6>
            <p>{new Date(incidentDetails.timestamp).toLocaleString()}</p>
            <Divider sx={{ marginBottom: "3rem" }} />
            <h6>Place</h6>
            <p>{incidentDetails.area}</p>
            <Divider sx={{ marginBottom: "3rem" }} />
            <h6>Incident Detection</h6>
            <p>{alert.name}</p>
            <Divider sx={{ marginBottom: "3rem" }} />
            <h6>Status</h6>
            <p
              style={{
                fontSize: "14px",
                color: incidentDetails.isResolved ? "#007C53" : "#e90707",
                textAlign: "center",
                width: "100%",
                backgroundColor: incidentDetails.isResolved
                  ? "#D6F4EA"
                  : "#F784842B",
                borderRadius: "11px",
                padding: "4px 0",
                marginBottom: "4rem",
              }}
            >
              {incidentDetails.isResolved ? "Resolved" : "Active"}
            </p>
            <h6>Recommendation</h6>
            <div>
              <div>
                <img src={toolIcon} alt="tool" />
                <p>
                  Suggest further steps like system audits, additional
                  monitoring tools, or enhanced protocols.
                </p>
              </div>
              <div>
                <img src={monitorIcon} alt="monitor" />
                <p>
                  Advise on how the system will be monitored going forward to
                  detect and prevent similar incidents.
                </p>
              </div>
            </div>
          </div>
          <div className={styles.btnSection}>
            <button
              onClick={handleUpdateAnomalyClick}
              style={{
                backgroundColor: incidentDetails.isResolved
                  ? "#8C8C8C"
                  : "#009965",
              }}
              disabled={incidentDetails.isResolved}
            >
              <p>Accept</p>
              <img src={acceptIcon} alt="accept" />
            </button>
            <button onClick={onBack} style={{ backgroundColor: "#F95959" }}>
              <p>Cancel</p>
              <img src={cancelIcon} alt="cancel" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AlertDetail;
