import { BASE_API_V1 } from "../utils/config";

const getAuthToken = () => {
  return localStorage.getItem("token");
};

export const FetchModelAccuracy = async () => {
  const response = await fetch(`${BASE_API_V1}/model-accuracy`);
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  const result = await response.json();
  return result.accuracy.toFixed(2);
};

export const FetchRealtimeAccuracy = async () => {
  const response = await fetch(`${BASE_API_V1}/accuracy-real-time`);
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  const result = await response.json();
  return Number(result).toFixed(2);
};

export const FetchIncidentResponseCount = async (days) => {
  const response = await fetch(
    `${BASE_API_V1}/Incident_response_status?days=${days}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getAuthToken()}`,
      },
    }
  );

  if (!response.ok) {
    throw new Error("Failed to fetch incident response");
  }

  const data = await response.json();
  const total = Number(data.resolved_count + data.unresolved_count);
  const resolved = ((Number(data.resolved_count) / total) * 100).toFixed(2);
  const unresolved = ((Number(data.unresolved_count) / total) * 100).toFixed(2);
  return { resolved, unresolved };
};

export const FetchAreaList = async () => {
  const response = await fetch(`${BASE_API_V1}/cameras_list`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getAuthToken()}`,
    },
  });

  if (!response.ok) {
    throw new Error("Failed to fetch cameras");
  }

  const data = await response.json();
  return data.map((camera) => camera.area_name);
};

export const FetchTimeSpentData = async (area, known_status) => {
  const response = await fetch(
    `${BASE_API_V1}/time-spent?area=${area}&known_status=${known_status}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  if (!response.ok) {
    throw new Error("Failed to fetch time spent data");
  }

  const data = await response.json();
  return data.data
    .filter((item) => item.total_time_spent_minutes !== null)
    .map((item) => ({
      name: item.name || "Unknown",
      timeSpent: item.total_time_spent_minutes || 0,
    }));
};

export const FetchTotalCameras = async () => {
  const response = await fetch(`${BASE_API_V1}/camera_count`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getAuthToken()}`,
    },
  });

  if (!response.ok) {
    throw new Error("Failed to fetch cameras");
  }

  const data = await response.json();
  return data.count;
};

export const FetchIncidentData = async (days) => {
  const response = await fetch(`${BASE_API_V1}/Incident_recorded?days=${days}`);
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  const data = await response.json();
  const total = Number(data.total_incident);
  const low = Number(data.Low_incident) || 0;
  const moderate = Number(data.Moderate_incident) || 0;
  const critical = Number(data.Critical_incident) || 0;

  return {
    total,
    percentages: [
      {
        value: ((low / total) * 100).toFixed(2),
        color: "#FF9900",
        label: "Low Incident",
      },
      {
        value: ((moderate / total) * 100).toFixed(2),
        color: "#FF5C00",
        label: "Moderate Incident",
      },
      {
        value: ((critical / total) * 100).toFixed(2),
        color: "#D40511",
        label: "Critical Incident",
      },
    ],
  };
};

export const FetchAvgResponsebyHour = async () => {
  const response = await fetch(`${BASE_API_V1}/average_response_by_hour`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getAuthToken()}`,
    },
  });
  const data = await response.json();
  return data;
};

export const FetchAnomaliesData = async () => {
  const response = await fetch(`${BASE_API_V1}/anomalies_by_area_per_week`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getAuthToken()}`,
    },
  });
  const data = await response.json();
  return data;
};

export const FetchDetectionCount = async (days, area) => {
  const response = await fetch(
    `${BASE_API_V1}/detection_count?days=${days}&area=${area}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getAuthToken()}`,
      },
    }
  );
  const data = await response.json();
  return data;
};

export const FetchAvgIdeal = async (employee_id) => {
  const response = await fetch(
    `${BASE_API_V1}/user-logs-for-employee-idel-time?employee_id=${employee_id}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getAuthToken()}`,
      },
    }
  );
  const data = await response.json();
  return data;
};
