import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { Helmet } from "react-helmet";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import xenonstacklogo from "../../static/xenonstack-black-logo.svg";
import login from "../../static/Login.svg";
import dividertag from "../../static/divider-tag-login.png";
import googleicon from "../../static/google-icon.png";
import microsofticon from "../../static/microsoft-icon.png";
import "../Authentication/Login.css";
import "../../common-files/primary-new.css";
import "../../common-files/grid-new.css";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { BASE_API_V1 } from "../../utils/config.js";
import { handleSetUser } from "../../services/user.service.jsx";

function Login() {
  const navigate = useNavigate();
  const [emailid, setEmailid] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  // const isAuthenticated = localStorage.getItem("isLoggedIn") === "true";

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  const handleSignIn = async () => {
    console.log("handleSignIn called");
    try {
      const formData = new URLSearchParams();
      formData.append("username", emailid);
      formData.append("password", password);

      const response = await fetch(`${BASE_API_V1}/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: formData,
      });

      if (response.ok) {
        console.log("Success");
        response.json().then((data) => {
          const token = data.access_token;
          const name = data.name;
          const email = data.email;
          toast.success("Login successful!");
          handleSetUser({ name, email });
          localStorage.setItem("isLoggedIn", "true");
          localStorage.setItem("token", token);
          localStorage.setItem("email", email);
          localStorage.setItem("name", name);
          if (rememberMe) {
            localStorage.setItem("isLoggedIn", "true");
            localStorage.setItem("token", token);
            localStorage.setItem("email", email);
            localStorage.setItem("name", name);
          } else {
            sessionStorage.setItem("isLoggedIn", "true");
            sessionStorage.setItem("token", token);
            localStorage.setItem("email", email);
            localStorage.setItem("name", name);
          }
          navigate("/home");
        });
      } else {
        console.log("Failed to login");
        const data = await response.json();
        setErrorMessage(data.message || "Login failed. Please try again.");
        toast.error("Invalid Email or Password");
      }
    } catch (error) {
      setErrorMessage("An error occurred. Please try again.");
      toast.error("An error occurred!");
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSignIn();
    }
  };

  return (
    <>
      <div>
        <Helmet>
          <title>Akira AI | Platform Login</title>
        </Helmet>
      </div>
      <div className="login-page">
        <div className="left-section">
          <div className="login-wrapper">
            <div className="login-logo">
              <img
                src={xenonstacklogo}
                alt="xenonstack-ai-logo"
                draggable="false"
              />
            </div>
            <div className="login-heading">
              <h2>Welcome Back 👋</h2>
              <p>
                Today is a new day. It's your day. You shape it. Sign in to
                start managing your projects.
              </p>
            </div>
            <div className="login-section">
              <div className="form-group">
                <label htmlFor="emailid">Email Id</label>
                <input
                  type="text"
                  id="emailid"
                  placeholder="Email Address"
                  value={emailid}
                  onChange={(e) => setEmailid(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label htmlFor="password">Password</label>
                <div className="password-input-wrapper">
                  <input
                    type={isPasswordVisible ? "text" : "password"}
                    id="password"
                    placeholder="Password"
                    value={password}
                    onChange={handlePasswordChange}
                    onKeyPress={handleKeyPress}
                  />
                  <button
                    type="button"
                    className="toggle-password-visibility"
                    onClick={togglePasswordVisibility}
                  >
                    <i className="material-icons">
                      {isPasswordVisible ? <Visibility /> : <VisibilityOff />}
                    </i>
                  </button>
                </div>
              </div>
              <div className="form-group-remember">
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={rememberMe}
                        onChange={() => setRememberMe(!rememberMe)}
                      />
                    }
                    label="Remember Me"
                  />
                </FormGroup>
              </div>
              {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
              <button className="login-button" onClick={handleSignIn}>
                Log In
              </button>
              <div className="divider-tag">
                <img src={dividertag} alt="divider-tag" />
              </div>
              <div className="signin-button-wrapper">
                <div className="signin-with-google-button">
                  <img src={googleicon} alt="google-icon" />
                  <p>Sign In Using Google</p>
                </div>
                <div className="signin-with-microsoft-button">
                  <img src={microsofticon} alt="microsoft-icon" />
                  <p>Sign In Using Microsoft</p>
                </div>
              </div>
            </div>
            <div className="powered-by-text">
              <p>Don't you have an account?</p>
              <span>
                <Link to="/signup">Sign Up</Link>
              </span>
            </div>
          </div>
        </div>
        <div className="blue-section">
          <img src={login} alt="login-image" draggable="false" />
        </div>
      </div>
      <ToastContainer position="top-right" autoClose={5000} />
    </>
  );
}

export default Login;
