// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AnomaliesPerIncident_AnomaliesPerIncident__EVQ9z {
  width: 100%;
  background-color: #ffffff;
  border-radius: 22px;
  padding: 20px 30px;
  box-shadow: 0px 2px 12px 0px #536fcb24;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  height: 100%;
}

.AnomaliesPerIncident_AnomaliesPerIncidentHeader__xaGzg {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.AnomaliesPerIncident_AnomaliesPerIncidentHeader__xaGzg h4 {
  font-size: 14px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: -0.02em;
  color: #000000;
  margin: 0;
}

.AnomaliesPerIncident_chartFilter__3ed3\\+ {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.AnomaliesPerIncident_areaDropdown__b4rY1 {
  padding: 10px;
  border-radius: 5px;
  border: none !important;
  font-weight: 600;
  font-size: 14px;
}
`, "",{"version":3,"sources":["webpack://./src/Components/AnomaliesPerIncident/AnomaliesPerIncident.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,yBAAyB;EACzB,mBAAmB;EACnB,kBAAkB;EAClB,sCAAsC;EACtC,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,gBAAgB;EAChB,YAAY;AACd;;AAEA;EACE,WAAW;EACX,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,uBAAuB;EACvB,cAAc;EACd,SAAS;AACX;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,yBAAyB;AAC3B;;AAEA;EACE,aAAa;EACb,kBAAkB;EAClB,uBAAuB;EACvB,gBAAgB;EAChB,eAAe;AACjB","sourcesContent":[".AnomaliesPerIncident {\n  width: 100%;\n  background-color: #ffffff;\n  border-radius: 22px;\n  padding: 20px 30px;\n  box-shadow: 0px 2px 12px 0px #536fcb24;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  overflow: hidden;\n  height: 100%;\n}\n\n.AnomaliesPerIncidentHeader {\n  width: 100%;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n\n.AnomaliesPerIncidentHeader h4 {\n  font-size: 14px;\n  font-weight: 700;\n  line-height: 28px;\n  letter-spacing: -0.02em;\n  color: #000000;\n  margin: 0;\n}\n\n.chartFilter {\n  display: flex;\n  align-items: center;\n  justify-content: flex-end;\n}\n\n.areaDropdown {\n  padding: 10px;\n  border-radius: 5px;\n  border: none !important;\n  font-weight: 600;\n  font-size: 14px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"AnomaliesPerIncident": `AnomaliesPerIncident_AnomaliesPerIncident__EVQ9z`,
	"AnomaliesPerIncidentHeader": `AnomaliesPerIncident_AnomaliesPerIncidentHeader__xaGzg`,
	"chartFilter": `AnomaliesPerIncident_chartFilter__3ed3+`,
	"areaDropdown": `AnomaliesPerIncident_areaDropdown__b4rY1`
};
export default ___CSS_LOADER_EXPORT___;
