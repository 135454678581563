export const getPriorityInfo = (priority) => {
  const priorityMap = {
    low: {
      color: "#FF9900",
      text: "Low Severity Alert",
      backgroundColor: "#FFF8DC",
    },
    medium: {
      color: "#FF5C00",
      text: "Moderate Alert",
      backgroundColor: "#FFE8CE",
    },
    critical: {
      color: "#D40511",
      text: "High Alert",
      backgroundColor: "#FFDADC",
    },
  };
  return (
    priorityMap[priority.toLowerCase()] || {
      color: "#808080",
      text: "Unknown Priority",
    }
  );
};

const AnomalyIcon = ({ priority }) => {
  const color = getPriorityInfo(priority).color;

  return (
    <svg
      width="28"
      height="29"
      viewBox="0 0 28 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.3869 19.072L17.9202 5.63203C16.9169 3.8237 15.5286 2.83203 14.0002 2.83203C12.4719 2.83203 11.0836 3.8237 10.0802 5.63203L2.61355 19.072C1.66855 20.787 1.56355 22.432 2.32188 23.727C3.08022 25.022 4.57355 25.7337 6.53355 25.7337H21.4669C23.4269 25.7337 24.9202 25.022 25.6786 23.727C26.4369 22.432 26.3319 20.7754 25.3869 19.072ZM13.1252 10.9987C13.1252 10.5204 13.5219 10.1237 14.0002 10.1237C14.4785 10.1237 14.8752 10.5204 14.8752 10.9987V16.832C14.8752 17.3104 14.4785 17.707 14.0002 17.707C13.5219 17.707 13.1252 17.3104 13.1252 16.832V10.9987ZM14.8286 21.1604C14.7702 21.207 14.7119 21.2537 14.6536 21.3004C14.5836 21.347 14.5136 21.382 14.4436 21.4054C14.3736 21.4404 14.3035 21.4637 14.2219 21.4754C14.1519 21.487 14.0702 21.4987 14.0002 21.4987C13.9302 21.4987 13.8486 21.487 13.7669 21.4754C13.6969 21.4637 13.6269 21.4404 13.5569 21.4054C13.4869 21.382 13.4169 21.347 13.3469 21.3004C13.2885 21.2537 13.2302 21.207 13.1719 21.1604C12.9619 20.9387 12.8336 20.6354 12.8336 20.332C12.8336 20.0287 12.9619 19.7254 13.1719 19.5037C13.2302 19.457 13.2885 19.4104 13.3469 19.3637C13.4169 19.317 13.4869 19.282 13.5569 19.2587C13.6269 19.2237 13.6969 19.2004 13.7669 19.1887C13.9186 19.1537 14.0819 19.1537 14.2219 19.1887C14.3035 19.2004 14.3736 19.2237 14.4436 19.2587C14.5136 19.282 14.5836 19.317 14.6536 19.3637C14.7119 19.4104 14.7702 19.457 14.8286 19.5037C15.0386 19.7254 15.1669 20.0287 15.1669 20.332C15.1669 20.6354 15.0386 20.9387 14.8286 21.1604Z"
        fill={color}
      />
    </svg>
  );
};

export default AnomalyIcon;

export const PriorityIcon = ({ priority }) => {
  const color = getPriorityInfo(priority).color;
  const text = getPriorityInfo(priority).text;

  return (
    <>
      <svg
        width="22"
        height="23"
        viewBox="0 0 28 29"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M25.3869 19.072L17.9202 5.63203C16.9169 3.8237 15.5286 2.83203 14.0002 2.83203C12.4719 2.83203 11.0836 3.8237 10.0802 5.63203L2.61355 19.072C1.66855 20.787 1.56355 22.432 2.32188 23.727C3.08022 25.022 4.57355 25.7337 6.53355 25.7337H21.4669C23.4269 25.7337 24.9202 25.022 25.6786 23.727C26.4369 22.432 26.3319 20.7754 25.3869 19.072ZM13.1252 10.9987C13.1252 10.5204 13.5219 10.1237 14.0002 10.1237C14.4785 10.1237 14.8752 10.5204 14.8752 10.9987V16.832C14.8752 17.3104 14.4785 17.707 14.0002 17.707C13.5219 17.707 13.1252 17.3104 13.1252 16.832V10.9987ZM14.8286 21.1604C14.7702 21.207 14.7119 21.2537 14.6536 21.3004C14.5836 21.347 14.5136 21.382 14.4436 21.4054C14.3736 21.4404 14.3035 21.4637 14.2219 21.4754C14.1519 21.487 14.0702 21.4987 14.0002 21.4987C13.9302 21.4987 13.8486 21.487 13.7669 21.4754C13.6969 21.4637 13.6269 21.4404 13.5569 21.4054C13.4869 21.382 13.4169 21.347 13.3469 21.3004C13.2885 21.2537 13.2302 21.207 13.1719 21.1604C12.9619 20.9387 12.8336 20.6354 12.8336 20.332C12.8336 20.0287 12.9619 19.7254 13.1719 19.5037C13.2302 19.457 13.2885 19.4104 13.3469 19.3637C13.4169 19.317 13.4869 19.282 13.5569 19.2587C13.6269 19.2237 13.6969 19.2004 13.7669 19.1887C13.9186 19.1537 14.0819 19.1537 14.2219 19.1887C14.3035 19.2004 14.3736 19.2237 14.4436 19.2587C14.5136 19.282 14.5836 19.317 14.6536 19.3637C14.7119 19.4104 14.7702 19.457 14.8286 19.5037C15.0386 19.7254 15.1669 20.0287 15.1669 20.332C15.1669 20.6354 15.0386 20.9387 14.8286 21.1604Z"
          fill={color}
        />
      </svg>
      <p style={{ color: color }}>{text}</p>
    </>
  );
};
