import { store } from "../store";
import { setUser, clearUser, fetchUserRole } from "../actions/user.actions";
import { useSelector } from "react-redux";

export const handleSetUser = (user) => {
  return store.dispatch(setUser(user));
};

export const handleClearUser = () => {
  return store.dispatch(clearUser());
};

export const handleFetchUserRole = () => {
  return store.dispatch(fetchUserRole());
};

export const useUser = () => useSelector((state) => state.user.user);
export const useUserRole = () => useSelector((state) => state.user.userRole);
export const useUserIsLoading = () =>
  useSelector((state) => state.user.isLoading);
export const useUserError = () => useSelector((state) => state.user.error);
