import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { Button, TextField, CircularProgress } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";

const ChatBotContainer = styled("div")`
  position: fixed;
  right: ${({ open }) => (open ? "0" : "-600px")};
  top: 0;
  width: 600px;
  height: 100vh;
  background-color: #ffffff;
  box-shadow: -2px 0 10px rgba(0, 0, 0, 0.1);
  border-top-left-radius: 4rem;
  border-bottom-left-radius: 4rem;
  transition: right 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  z-index: 1000;
`;

const ChatHeader = styled("div")`
  border-top-left-radius: 4rem;
  border-bottom-left-radius: 4rem;
  padding: 20px 4rem;
  background-color: #16458a;
  color: var(--clr-white);
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ChatHeading = styled("h3")`
  color: var(--clr-white);
  font-weight: 500;
  font-size: var(--size-4xl);
`;

const ChatMessages = styled("div")`
  flex: 1;
  overflow-y: auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
`;

const ChatInputContainer = styled("div")`
  padding: 20px;
  display: flex;
  gap: 10px;
`;

const Message = styled("div")`
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 8px;
  max-width: 80%;
  ${({ isUser }) =>
    isUser
      ? `
    align-self: flex-end;
    background-color: #e3f2fd;
    border-bottom-right-radius: 0;
  `
      : `
    align-self: flex-start;
    background-color: #f5f5f5;
    border-bottom-left-radius: 0;
  `}
`;

const MessageContainer = styled("div")`
  display: flex;
  justify-content: ${({ isUser }) => (isUser ? "flex-end" : "flex-start")};
  margin-bottom: 10px;
`;

const ChatBot = ({ open, onClose }) => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleSend = async () => {
    if (input.trim() === "") return;

    const newMessage = { text: input, isUser: true };
    setMessages((prevMessages) => [...prevMessages, newMessage]);
    setInput("");
    setIsLoading(true);

    try {
      const response = await fetch(
        `https://xenonstack-ai-app-ai-backend.lab.neuralcompany.team/query_sql?query=${input}`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
        //   body: JSON.stringify({ query: input }),
        }
      );

      if (!response.ok) throw new Error("API request failed");

      const data = await response.json();
      const botResponse = {
        text: data.result,
        isUser: false,
        tablesUsed: data.tables_used,
      };
      setMessages((prevMessages) => [...prevMessages, botResponse]);
    } catch (error) {
      console.error("Error fetching chatbot response:", error);
      const errorMessage = {
        text: "Sorry, I encountered an error. Please try again.",
        isUser: false,
      };
      setMessages((prevMessages) => [...prevMessages, errorMessage]);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ChatBotContainer open={open}>
      <ChatHeader>
        <ChatHeading>Ask anything or write with AI</ChatHeading>
        <CustomButton variant="contained" onClick={onClose}>
          End Chat
        </CustomButton>
      </ChatHeader>
      <ChatMessages>
        {messages.map((message, index) => (
          <MessageContainer key={index} isUser={message.isUser}>
            <Message isUser={message.isUser}>
              {message.text}
              {/* {!message.isUser && message.tablesUsed && (
                <div
                  style={{
                    marginTop: "10px",
                    fontSize: "0.8em",
                    color: "#666",
                  }}
                >
                  Tables used: {message.tablesUsed.join(", ")}
                </div>
              )} */}
            </Message>
          </MessageContainer>
        ))}
        {isLoading && (
          <MessageContainer isUser={false}>
            <Message isUser={false}>
              <CircularProgress size={20} />
            </Message>
          </MessageContainer>
        )}
      </ChatMessages>
      <ChatInputContainer>
        <StyledTextField
          fullWidth
          variant="outlined"
          placeholder="Type your message..."
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyPress={(e) => e.key === "Enter" && handleSend()}
        />
        <CustomButton
          sx={{ padding: "1rem 3rem", borderRadius: ".75rem" }}
          variant="outlined"
          endIcon={<SendIcon />}
          onClick={handleSend}
          disabled={isLoading}
        >
          Send
        </CustomButton>
      </ChatInputContainer>
    </ChatBotContainer>
  );
};

export default ChatBot;

const CustomButton = styled(Button)(({ theme, selected }) => ({
  color: "var(--clr-dark)",
  border: "1px solid #e0e0e0",
  borderRadius: ".75rem",
  fontSize: "var(--size-3xl)",
  textTransform: "capitalize",
  fontWeight: "500",
  padding: ".5rem 1rem",
  backgroundColor: "var(--clr-white)",
  "&:hover": {
    backgroundColor: "#e0e0e0",
  },
  ...(selected && {
    backgroundColor: "var(--clr-dark)",
    color: "var(--clr-white)",
  }),
}));

const StyledTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderRadius: ".75rem",
      borderColor: "#e0e0e0",
    },
    "&:hover fieldset": { borderColor: "var(--clr-dark)" },
    "&.Mui-focused fieldset": { borderColor: "var(--clr-dark)" },
  },
  "& .MuiInputLabel-root": {
    color: "var(--clr-dark)",
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: "var(--clr-dark)",
  },
  "& .MuiInputBase-input": {
    color: "var(--clr-dark)",
  },
  "& .MuiInputBase-input::placeholder": {
    color: "var(--clr-dark)",
  },
});
