import React from "react";
import styles from "./InfoCards.module.css";

function InfoCards({ ImgSrc, Title, Count }) {
  return (
    <div
      className={styles.InfoCards}
    >
      <div className={styles.ImgContainer}>
        <img src={ImgSrc} alt="img" draggable="false" />
      </div>
      <div className={styles.dataContainer}>
        <h4>{Title}</h4>
        <p>{Count}</p>
      </div>
    </div>
  );
}

export default InfoCards;
