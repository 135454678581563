import * as types from "../actionTypes/user.types";
import { fetchUserRoleApi } from "../api/user.api";

export const setUser = (user) => ({
  type: types.SET_USER,
  payload: user,
});

export const clearUser = () => ({
  type: types.CLEAR_USER,
});

export const fetchUserRole = () => async (dispatch) => {
  dispatch({ type: types.FETCH_USER_ROLE_REQUEST });
  try {
    const data = await fetchUserRoleApi();
    dispatch({
      type: types.FETCH_USER_ROLE_SUCCESS,
      payload: data.role,
    });
  } catch (error) {
    dispatch({
      type: types.FETCH_USER_ROLE_FAILURE,
      payload: error.message,
    });
  }
};
