import { PieChart } from "@mui/x-charts/PieChart";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { styled } from "@mui/material/styles";
import styles from "./PieChartView.module.css";
import { useState } from "react";

const StyledSelect = styled(Select)(({ theme }) => ({
  "& .MuiSelect-select": {
    padding: "0px",
    fontSize: "16px",
    fontWeight: "500",
    lineHeight: "24px",
    display: "flex",
    alignItems: "center",
    minHeight: "24px",
    "&:focus": {
      backgroundColor: "transparent",
    },
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
  "& .MuiSvgIcon-root": {
    right: "4px",
    fontSize: "24px",
  },
}));

function PieChartView({
  Title,
  DropDown = false,
  DropValue,
  PieValue,
  numberCount,
  setDays,
}) {
  const [status, setStatus] = useState(DropValue);

  const handleStatusChange = (event) => {
    const newStatus = event.target.value;
    setStatus(newStatus);
    if (setDays) {
      if (newStatus === "Monthly") {
        setDays(30);
      } else if (newStatus === "Weekly") {
        setDays(7);
      }
    }
  };

  return (
    <div className={styles.PieChartView}>
      <div className={styles.PieChartViewHeading}>
        <h2>{Title}</h2>
        {DropDown && (
          <StyledSelect
            value={status}
            onChange={handleStatusChange}
            sx={{
              color: "#A3AED0",
              backgroundColor: "transparent",
            }}
          >
            <MenuItem value="Monthly" sx={{ fontSize: "16px" }}>
              Monthly
            </MenuItem>
            <MenuItem value="Weekly" sx={{ fontSize: "16px" }}>
              Weekly
            </MenuItem>
          </StyledSelect>
        )}
      </div>
      <div className={styles.PieChartContainer}>
        <PieChart
          series={[
            {
              data: PieValue,
              innerRadius: 50,
              outerRadius: 100,
              paddingAngle: 2,
              cornerRadius: 5,
              startAngle: 0,
              endAngle: 360,
              cx: 150,
              cy: 150,
              highlightScope: { faded: "global", highlighted: "item" },
              faded: { innerRadius: 30, additionalRadius: -30, color: "gray" },
            },
          ]}
          slotProps={{
            legend: {
              hidden: true,
            },
          }}
          width={300}
          height={300}
        />
      </div>
      <div className={styles.labelDataContainer}>
        {numberCount === 1 ? (
          <div className={styles.labelData}>
            <div>
              <span
                style={{ backgroundColor: `${PieValue[0].color}` }}
                className={styles.dot}
              ></span>
              <p>{PieValue[0].label}</p>
            </div>
            <p>{`${Number(PieValue[0].value).toFixed(2)}%`}</p>
          </div>
        ) : (
          PieValue.map((value, index) => (
            <div key={index} className={styles.labelData}>
              <div>
                <span
                  style={{ backgroundColor: `${value.color}` }}
                  className={styles.dot}
                ></span>
                <p>{value.label}</p>
              </div>
              <p>{`${Number(value.value).toFixed(2)}%`}</p>
            </div>
          ))
        )}
      </div>
    </div>
  );
}

export default PieChartView;
