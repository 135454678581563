import React, { useEffect } from "react";
import styles from "./AvgEmployeeIdealTime.module.css";
import { BarChart } from "@mui/x-charts/BarChart";
import { axisClasses } from "@mui/x-charts/ChartsAxis";
import { useSelector } from "react-redux";
import { handleFetchAvgIdeal } from "../../services/analysis.service";

function AvgEmployeeIdealTime() {
  const employeeData = useSelector((state) => state.analysis.avgIdeal);
  const loading = useSelector((state) => state.analysis.loading);
  const error = useSelector((state) => state.analysis.error);

  useEffect(() => {
    handleFetchAvgIdeal("all");
  }, []);

  const EmployeeTimeDistributionChart = ({ data }) => {
    if (!data || Object.keys(data).length === 0) return null;

    const employeeIds = Object.values(data).map(
      (employee) => employee.employee_id
    );

    const valueFormatter = (value) => `${value.toFixed(2)} hr`;

    const idleTimeSeries = {
      data: Object.values(data).map((employee) => employee.idel_time),
      label: "Idle Time",
      stack: "total",
      color: "#FE6E06",
      valueFormatter,
    };

    const sessionTimeSeries = {
      data: Object.values(data).map((employee) => employee.total_session_time),
      label: "Session Time",
      stack: "total",
      color: "#FF6B0040",
      valueFormatter,
    };

    const totalTimeSeries = {
      id: "totalTime",
      data: Object.values(data).map((employee) => employee.total_time),
      label: "Total Time",
      stack: "total",
      color: "#FE6E060D",
      valueFormatter,
    };

    return (
      <BarChart
        height={400}
        series={[idleTimeSeries, sessionTimeSeries, totalTimeSeries]}
        xAxis={[
          {
            data: employeeIds,
            scaleType: "band",
            label: "Employee ID",
            valueFormatter: (value) => `XEN0${value}`,
            labelStyle: {
              fill: "#667085",
            },
          },
        ]}
        yAxis={[
          {
            label: "Time (in hours)",
            valueFormatter: (value) => `${value}hr`,
            labelStyle: {
              fill: "#667085",
            },
          },
        ]}
        slotProps={{
          bar: ({ id }) =>
            id === "totalTime"
              ? {
                  clipPath: `inset(0px round 12px 12px 0px 0px)`,
                }
              : {},
          legend: {
            direction: "row",
            position: { vertical: "top", horizontal: "right" },
            itemMarkWidth: 8,
            itemMarkHeight: 8,
            itemGap: 20,
            labelStyle: {
              fontSize: 12,
              fill: "#667085",
            },
          },
        }}
        borderRadius={10}
        grid={{
          horizontal: true,
          stroke: "#F2F4F7",
        }}
        leftAxis={{
          disableLine: true,
          disableTicks: true,
        }}
        bottomAxis={{
          disableLine: true,
          disableTicks: true,
        }}
        margin={{ left: 60, right: 0, bottom: 50 }}
        sx={{
          [`.${axisClasses.left} .${axisClasses.label}`]: {
            transform: "translate(-20px, 0)",
          },
          [`.${axisClasses.bottom} .${axisClasses.label}`]: {
            transform: "translate(0, 10px)",
          },
          [`.${axisClasses.root}`]: {
            [`.${axisClasses.tickLabel}`]: {
              fill: "#667085",
            },
          },
        }}
      />
    );
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className={styles.AvgEmployeeIdealTime}>
      <h4>Avg Idle Time by hour of the week</h4>
      {employeeData ? (
        <EmployeeTimeDistributionChart data={employeeData} />
      ) : (
        <p>No data available</p>
      )}
    </div>
  );
}

export default AvgEmployeeIdealTime;
