import { useCallback, useEffect, useState } from "react";
import InfoCards from "../../../Components/InfoCards/InfoCards";
import AlertCards from "../../../Components/AlertCards/AlertCards";
import cameraIMG from "../../../icon/camera.svg";
import AlertDetail from "./alertDetail";
import styles from "./alert.module.css";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import { useSelector } from "react-redux";
import { handleFetchAlerts } from "../../../services/alert.service";

const Alert = () => {
  const { alerts, totalAlerts, resolvedAlerts, pendingAlerts, loading, error } =
    useSelector((state) => state.alert);

  const [selectedAlert, setSelectedAlert] = useState(null);
  const [page, setPage] = useState(1);
  const [selectedOption, setSelectedOption] = useState("All Incident");
  const rowsPerPage = 9;

  const loadAlerts = useCallback(() => {
    handleFetchAlerts(page, rowsPerPage, selectedOption);
  }, [page, rowsPerPage, selectedOption]);

  useEffect(() => {
    loadAlerts();
  }, [loadAlerts]);

  const handleAlertClick = (alert) => {
    setSelectedAlert(alert);
  };

  const handleBackClick = () => {
    setSelectedAlert(null);
    loadAlerts();
  };

  const toggleOption = (option) => {
    setSelectedOption(option);
    setPage(1);
  };

  const inforCardData = [
    { img: cameraIMG, title: "Total Alerts", count: totalAlerts },
    { img: cameraIMG, title: "Issues Resolved", count: resolvedAlerts },
    { img: cameraIMG, title: "Issues Pending", count: pendingAlerts },
  ];

  const options = ["All Incident", "Active Incidents", "Resolved Incident"];

  const getPaginationTotal = () => {
    switch (selectedOption) {
      case "Active Incidents":
        return pendingAlerts;
      case "Resolved Incident":
        return resolvedAlerts;
      default:
        return totalAlerts;
    }
  };

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (selectedAlert) {
    return <AlertDetail alert={selectedAlert} onBack={handleBackClick} />;
  }

  return (
    <div className={styles.Alert}>
      <div>
        <button>
          <p>Security And Alerts</p>
        </button>
      </div>
      <div className={styles.infoCardsContainer}>
        {inforCardData.map((card, index) => (
          <InfoCards
            key={index}
            ImgSrc={card.img}
            Title={card.title}
            Count={card.count}
          />
        ))}
      </div>
      <div className={styles.OptionsHeader}>
        <h3>Recorded Incident</h3>
        <div>
          <ul className={styles.Options}>
            {options.map((option, index) => (
              <li
                key={index}
                onClick={() => toggleOption(option)}
                className={selectedOption === option ? styles.selected : ""}
              >
                {option}
              </li>
            ))}
          </ul>
        </div>
      </div>

      {loading ? (
        <div className={styles.loaderContainer}>
          <CircularProgress />
        </div>
      ) : alerts === null || alerts.length === 0 ? (
        <p className={styles.noAlertText}>No alerts found.</p>
      ) : (
        <div className={styles.alertCardsContainer}>
          {alerts.map((card, index) => (
            <AlertCards
              key={index}
              Date={card.timestamp}
              ID={card.case_id}
              Priority={card.priority}
              ImgSrc={card.image}
              Title={card.name}
              Status={card.is_resolved ? "Resolved" : "Active"}
              Description={card.content}
              onClick={() => handleAlertClick(card)}
              fetchAlerts={loadAlerts}
            />
          ))}
        </div>
      )}

      {alerts && alerts.length > 0 && (
        <Stack spacing={2} className={styles.paginationContainer}>
          <Pagination
            count={Math.ceil(getPaginationTotal() / rowsPerPage)}
            page={page}
            onChange={(event, value) => setPage(value)}
            size="large"
            shape="rounded"
            sx={{
              "& .MuiPaginationItem-root": {
                fontSize: "2rem",
                marginBottom: "0px",
              },
              "& .MuiPaginationItem-icon": {
                fontSize: "3rem",
                marginBottom: "0px",
              },
            }}
          />
        </Stack>
      )}
    </div>
  );
};

export default Alert;
