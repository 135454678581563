import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, NavLink, Outlet } from "react-router-dom";
import {
  MaterialDesignContent,
  SnackbarProvider,
  useSnackbar,
} from "notistack";
import Slide from "@mui/material/Slide";
import homeIcon from "../../../icon/Homepage.svg";
import alert from "../../../icon/Alert.svg";
import analysis from "../../../icon/Analysis.svg";
import xenonstacklogo from "../../../Images/xenonstack-website-new-logo-white.svg";
import alertCloseIMG from "../../../icon/icons8-close.svg";
import userIMG from "../../../icon/icons8-user.png";
import logoutIMG from "../../../icon/log-out.svg";
import alertSoundFile from "../../../assets/sounds/popcorn.mp3";
import "./home.css";
import { styled } from "@mui/material";
import { BASE_API_V1 } from "../../../utils/config";
import {
  useUser,
  useUserRole,
  handleFetchUserRole,
  handleClearUser,
} from "../../../services/user.service";

const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
  "&.notistack-MuiContent-error": {
    backgroundColor: "#FFDADC",
    fontSize: "14px",
    color: "#D40511",
    borderLeft: "2px solid #D40511",
  },
}));

const HomeContent = () => {
  const navigate = useNavigate();
  const user = useUser();
  const userRole = useUserRole();
  const [showOverlay, setShowOverlay] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const alertSound = new Audio(alertSoundFile);
  const [canPlaySound, setCanPlaySound] = useState(false);

  const handleUserInteraction = useCallback(() => {
    setCanPlaySound(true);
    document.removeEventListener("click", handleUserInteraction);
    document.removeEventListener("keydown", handleUserInteraction);
  }, []);

  useEffect(() => {
    document.addEventListener("click", handleUserInteraction);
    document.addEventListener("keydown", handleUserInteraction);

    return () => {
      document.removeEventListener("click", handleUserInteraction);
      document.removeEventListener("keydown", handleUserInteraction);
    };
  }, [handleUserInteraction]);

  useEffect(() => {
    handleFetchUserRole();
  }, [user]);

  // useEffect(() => {
  //   if (user && !userRole) {
  //     handleFetchUserRole();
  //   }
  // }, [user, userRole]);

  useEffect(() => {
    if (!user && !localStorage.getItem("email")) {
      console.error("User email not available");
      return;
    }
    const eventEmail = user?.email
      ? user?.email
      : localStorage.getItem("email");
    const eventSource = new EventSource(
      `${BASE_API_V1}/stream/?email=${encodeURIComponent(eventEmail)}`
    );

    eventSource.onmessage = (event) => {
      const jsonData = event.data.replace("data: ", "").trim();
      try {
        const parsedData = JSON.parse(jsonData);
        let parsedDate = new Date(parsedData.detected_at);
        parsedDate.setHours(parsedDate.getHours() + 5);
        parsedDate.setMinutes(parsedDate.getMinutes() + 30);
        const formattedDate = parsedDate.toLocaleDateString("en-US", {
          weekday: "long",
          year: "numeric",
          month: "long",
          day: "numeric",
        });
        const formattedTime = parsedDate.toLocaleTimeString("en-US", {
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        });
        const displayMessage = `Alert detected in ${parsedData.area} for ${parsedData.name} (ID: ${parsedData.id}) - ${formattedDate} at ${formattedTime}`;

        if (canPlaySound) {
          alertSound
            .play()
            .catch((error) => console.error("Error playing sound:", error));
        }

        enqueueSnackbar(displayMessage, {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          TransitionComponent: Slide,
          action: (key) => (
            <div
              onClick={() => closeSnackbar(key)}
              style={{ marginLeft: "-5px" }}
            >
              <img
                style={{ width: "24px", cursor: "pointer" }}
                src={alertCloseIMG}
                alt="close"
              />
            </div>
          ),
        });

        setShowOverlay(true);
        setTimeout(() => setShowOverlay(false), 1000);
      } catch (error) {
        console.error("Failed to parse JSON:", error);
      }
    };

    eventSource.onerror = (err) => {
      console.error("EventSource failed:", err);
      eventSource.close();
    };

    return () => {
      eventSource.close();
    };
  }, [enqueueSnackbar, closeSnackbar, canPlaySound]);

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("isLoggedIn");
    localStorage.removeItem("email");
    localStorage.removeItem("name");
    handleClearUser();
    handleFetchUserRole();
    navigate("/");
  };

  return (
    <div className="home-wrapper">
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(255, 0, 0, 0.5)",
          zIndex: 9999,
          transition: "opacity 0.5s ease-in-out",
          opacity: showOverlay ? 1 : 0,
          pointerEvents: showOverlay ? "auto" : "none",
        }}
      />
      <div className="side-navbar">
        <div className="Xenonstack-log">
          <img src={xenonstacklogo} alt="Xenonstack logo" draggable="false" />
        </div>
        <ul className="nav-tabs">
          <li>
            <NavLink
              to="/home"
              className={({ isActive }) => (isActive ? "active" : "")}
            >
              <img src={homeIcon} alt="Home icon" draggable="false" /> Home
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/analysis"
              className={({ isActive }) => (isActive ? "active" : "")}
            >
              <img src={analysis} alt="Analysis icon" draggable="false" />{" "}
              Analysis
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/alert"
              className={({ isActive }) => (isActive ? "active" : "")}
            >
              <img src={alert} alt="Alert icon" draggable="false" /> Alerts
            </NavLink>
          </li>
        </ul>
        <div className="userRole">
          <p>{userRole?.toString().replace(/_/g, " ")}</p>
        </div>
        <div className="logout">
          <div>
            <img src={userIMG} alt="user" />
            <div>
              <p>{user?.name || localStorage.getItem("name")}</p>
              <p
                style={{
                  color: "#F2F4F7",
                  fontWeight: "400",
                  fontSize: "12px",
                }}
              >
                {user?.email || localStorage.getItem("email")}
              </p>
            </div>
          </div>
          <img
            src={logoutIMG}
            alt="logout"
            style={{ cursor: "pointer" }}
            onClick={handleLogout}
          />
        </div>
      </div>
      <div className="tab-content">
        <Outlet />
      </div>
    </div>
  );
};

const Home = () => {
  return (
    <SnackbarProvider
      maxSnack={3}
      Components={{
        error: StyledMaterialDesignContent,
      }}
    >
      <HomeContent />
    </SnackbarProvider>
  );
};

export default Home;
