import { BASE_API_V1 } from "../../../utils/config.js";

const getAuthToken = () => {
  return localStorage.getItem("token");
};

export const fetchCameras = async () => {
  try {
    const response = await fetch(`${BASE_API_V1}/cameras_list`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getAuthToken()}`,
      },
    });

    if (response.ok) {
      return await response.json();
    } else {
      throw new Error("Failed to fetch cameras");
    }
  } catch (error) {
    console.error("Error fetching cameras:", error);
    throw error;
  }
};

export const addCamera = async (payload) => {
  try {
    const response = await fetch(`${BASE_API_V1}/camera`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getAuthToken()}`,
      },
      body: JSON.stringify(payload),
    });

    if (response.ok) {
      return await response.json();
    } else {
      const errorData = await response.json();
      throw new Error(`Failed to add camera: ${errorData.message}`);
    }
  } catch (error) {
    console.error("Error adding camera:", error);
    throw error;
  }
};

export const deleteCamera = async (cameraId) => {
  try {
    const response = await fetch(`${BASE_API_V1}/delete_camera`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getAuthToken()}`,
      },
      body: JSON.stringify({ id: String(cameraId) }),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    return await response.json();
  } catch (error) {
    console.error("Error deleting camera:", error);
    throw error;
  }
};

export const anomaliesDetection = async (rtsp_url) => {
  try {
    const response = await fetch(
      `${BASE_API_V1}/anomaly-list?rtsp_url=${rtsp_url}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getAuthToken()}`,
        },
      }
    );
    if (response.ok) {
      return await response.json();
    } else {
      throw new Error("Failed to fetch anomaly detection data");
    }
  } catch (error) {
    console.error("Error in anomaly detection:", error);
    throw error;
  }
};

export const objectDetection = async (
  rtsp_url,
  cameraId,
  status,
  theft_status,
  object_bay,
  ppe_kit
) => {
  try {
    const response = await fetch(
      `${BASE_API_V1}/detected-logs?rtsp_url=${rtsp_url}&id=${cameraId}&object_status=${status}&theft_status=${theft_status}&object_bay=${object_bay}&ppe_kit=${ppe_kit}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (response.ok) {
      return await response.json();
    } else {
      throw new Error("Failed to fetch object detection data");
    }
  } catch (error) {
    console.error("Error in object detection:", error);
    throw error;
  }
};

export const fetchOverCrowd = async (area, object_status) => {
  try {
    const response = await fetch(
      `${BASE_API_V1}/over-crowd?area_name=${area}&object_status=${object_status}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (response.ok) {
      return await response.json();
    } else {
      throw new Error("Failed to fetch data");
    }
  } catch (error) {
    console.error("Error in over crowd:", error);
    throw error;
  }
};
