// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Error_error__Ltspy {
  text-align: center;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

.Error_error__Ltspy h1 {
  font-size: var(--size-9xl);
}

.Error_error__Ltspy p {
  font-size: var(--size-4xl);
  margin-top: -1rem;
  margin-bottom: 2rem;
}

.Error_error__Ltspy button {
  font-size: var(--size-5xl);
  padding: 1rem;
  border-radius: 10px;
  border: transparent;
}
`, "",{"version":3,"sources":["webpack://./src/layout/ui/Error.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;EACb,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,SAAS;AACX;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,0BAA0B;EAC1B,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA;EACE,0BAA0B;EAC1B,aAAa;EACb,mBAAmB;EACnB,mBAAmB;AACrB","sourcesContent":[".error {\n  text-align: center;\n  height: 100vh;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  gap: 2rem;\n}\n\n.error h1 {\n  font-size: var(--size-9xl);\n}\n\n.error p {\n  font-size: var(--size-4xl);\n  margin-top: -1rem;\n  margin-bottom: 2rem;\n}\n\n.error button {\n  font-size: var(--size-5xl);\n  padding: 1rem;\n  border-radius: 10px;\n  border: transparent;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"error": `Error_error__Ltspy`
};
export default ___CSS_LOADER_EXPORT___;
