import React, { useEffect, useState } from "react";
import styles from "./EmployeeActivityAnalysis.module.css";
import { BarChart } from "@mui/x-charts/BarChart";
import { axisClasses } from "@mui/x-charts/ChartsAxis";
import { useSelector } from "react-redux";
import { handleFetchEmployeeActivityAnalysis } from "../../services/analysis.service";
import { Button, MenuItem, Select } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { styled } from "@mui/system";

const StyledSelect = styled(Select)(() => ({
  "& .MuiSelect-select": {
    padding: "0px",
    fontSize: "16px",
    fontWeight: "500",
    lineHeight: "24px",
    display: "flex",
    alignItems: "center",
    minHeight: "24px",
    "&:focus": {
      backgroundColor: "transparent",
    },
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
  "& .MuiSvgIcon-root": {
    right: "4px",
    fontSize: "24px",
  },
}));

function EmployeeActivityAnalysis() {
  const employeeActivity = useSelector(
    (state) => state.analysis.employeeActivity
  );
  const areas = useSelector((state) => state.analysis.areas);
  const error = useSelector((state) => state.analysis.error);
  const [selectedArea, setSelectedArea] = useState(areas[0] ? areas[0] : "602");
  const [selectDays, setSelectDays] = useState(7);

  const visibleBars = 7;
  const [startIndex, setStartIndex] = useState(0);

  const employeeIds = Object.keys(employeeActivity);
  const totalEmployees = employeeIds.length;

  const visibleData = employeeIds
    .slice(startIndex, startIndex + visibleBars)
    .reduce((acc, id) => {
      acc[id] = employeeActivity[id];
      return acc;
    }, {});

  const handleAreaChange = (event) => {
    setSelectedArea(event.target.value);
  };

  const handleFilterChange = (event) => {
    setSelectDays(event.target.value);
  };

  useEffect(() => {
    handleFetchEmployeeActivityAnalysis(selectedArea, selectDays);
  }, [selectedArea, selectDays]);

  const EmployeeActivityDistributionChart = ({ data }) => {
    if (!data || Object.keys(data).length === 0) return null;

    const employeeIds = Object.values(data).map(
      (employee) => employee.employee_name
    );

    const secondsToHours = (seconds) => seconds / 3600;

    const valueFormatter = (value) => `${value.toFixed(6)} hr`;

    const idleTimeSeries = {
      data: Object.values(data).map((employee) =>
        secondsToHours(employee.non_idle_time)
      ),
      label: "Non Idle Time",
      stack: "total",
      color: "#FE6E06",
      valueFormatter,
    };

    const sessionTimeSeries = {
      data: Object.values(data).map((employee) =>
        secondsToHours(employee.total_sitting_time)
      ),
      label: "Sitting Time",
      stack: "total",
      color: "#FF6B0040",
      valueFormatter,
    };

    const totalTimeSeries = {
      id: "totalTime",
      data: Object.values(data).map((employee) =>
        secondsToHours(employee.total_time)
      ),
      label: "Total Time",
      stack: "total",
      color: "#FE6E060D",
      valueFormatter,
    };

    return (
      <BarChart
        height={400}
        series={[idleTimeSeries, sessionTimeSeries, totalTimeSeries]}
        xAxis={[
          {
            data: employeeIds,
            scaleType: "band",
            label: "Employee ID",
            labelStyle: {
              fill: "#667085",
            },
          },
        ]}
        yAxis={[
          {
            label: "Time (in hours)",
            valueFormatter: (value) => `${value.toFixed(0)}hr`,
            labelStyle: {
              fill: "#667085",
            },
          },
        ]}
        slotProps={{
          noDataOverlay: { message: "No data to display in this chart" },

          bar: ({ id }) =>
            id === "totalTime"
              ? {
                  clipPath: `inset(0px round 12px 12px 0px 0px)`,
                }
              : {},
          legend: {
            direction: "row",
            position: { vertical: "top", horizontal: "right" },
            itemMarkWidth: 8,
            itemMarkHeight: 8,
            itemGap: 20,
            labelStyle: {
              fontSize: 12,
              fill: "#667085",
            },
          },
        }}
        borderRadius={10}
        grid={{
          horizontal: true,
          stroke: "#F2F4F7",
        }}
        leftAxis={{
          disableLine: true,
          disableTicks: true,
        }}
        bottomAxis={{
          disableLine: true,
          disableTicks: true,
        }}
        margin={{ left: 60, right: 0, bottom: 50 }}
        sx={{
          [`.${axisClasses.left} .${axisClasses.label}`]: {
            transform: "translate(-20px, 0)",
          },
          [`.${axisClasses.bottom} .${axisClasses.label}`]: {
            transform: "translate(0, 10px)",
          },
          [`.${axisClasses.root}`]: {
            [`.${axisClasses.tickLabel}`]: {
              fill: "#667085",
            },
          },
        }}
      />
    );
  };

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className={styles.AvgEmployeeIdealTime}>
      <div className={styles.optionContainer}>
        <h4>Employee Activity Analysis</h4>
        <div className={styles.chartFilter}>
          <div className={styles.areaDropdown}>
            <StyledSelect
              sx={{ color: "#a3aed0" }}
              value={selectedArea}
              onChange={handleAreaChange}
            >
              {areas.map((area) => (
                <MenuItem key={area} value={area} sx={{ fontSize: "16px" }}>
                  {area}
                </MenuItem>
              ))}
            </StyledSelect>
          </div>
          <div className={styles.areaDropdown}>
            <StyledSelect
              sx={{ color: "#a3aed0" }}
              value={selectDays}
              onChange={handleFilterChange}
            >
              <MenuItem value={30} sx={{ fontSize: "16px" }}>
                Monthly
              </MenuItem>
              <MenuItem value={7} sx={{ fontSize: "16px" }}>
                Weekly
              </MenuItem>
              <MenuItem value={1} sx={{ fontSize: "16px" }}>
                Daily
              </MenuItem>
            </StyledSelect>
          </div>
          <Button
            variant="outlined"
            size="large"
            onClick={() => setStartIndex(Math.max(0, startIndex - visibleBars))}
            disabled={startIndex === 0}
            sx={{
              marginLeft: "20px",
              padding: "8px 10px",
              border: "2px solid #a3aed0",
              borderRadius: "12px",
              color: "#a3aed0",
              "&:hover": {
                border: "2px solid black",
                color: "black",
              },
            }}
          >
            <ArrowBackIosIcon sx={{ fontSize: "20px" }} />
          </Button>
          <Button
            variant="outlined"
            size="large"
            onClick={() =>
              setStartIndex(
                Math.min(totalEmployees - visibleBars, startIndex + visibleBars)
              )
            }
            disabled={startIndex + visibleBars >= totalEmployees}
            sx={{
              padding: "8px 10px",
              marginLeft: "10px",
              border: "2px solid #a3aed0",
              borderRadius: "12px",
              color: "#a3aed0",
              "&:hover": {
                border: "2px solid black",
                color: "black",
              },
            }}
          >
            <ArrowForwardIosIcon sx={{ fontSize: "20px" }} />
          </Button>
        </div>
      </div>
      {Object.keys(visibleData).length > 0 ? (
        <EmployeeActivityDistributionChart data={visibleData} />
      ) : (
        <p>No data available</p>
      )}
    </div>
  );
}

export default EmployeeActivityAnalysis;
