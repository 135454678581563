import React, { useState, useEffect, useMemo } from "react";
import { BarChart } from "@mui/x-charts";
import PieChartView from "../../../Components/PieChartView/PieChartView";
import Box from "@mui/material/Box";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { styled } from "@mui/material/styles";
import { axisClasses } from "@mui/x-charts/ChartsAxis";
import { Grid } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import ThermalGraph from "../../../Components/ThermalGraph/ThermalGraph";
import CpuUsageGraph from "../../../Components/CpuUsageGraph/CpuUsageGraph";
import CameraFeedGraph from "../../../Components/CameraFeedGraph/CameraFeedGraph";
import InfoCards from "../../../Components/InfoCards/InfoCards";
import TotalCameraIMG from "../../../icon/TotalCamera.svg";
import TotalIncidentIMG from "../../../icon/TotalIncident.svg";
import CamerasOperationalIMG from "../../../icon/CamerasOperational.svg";
import CamerasNonOperationalIMG from "../../../icon/CamerasNonOperational.svg";
import DetectionCountGraph from "../../../Components/DetectionCountGraph/DetectionCountGraph.jsx";
import AnomaliesPerIncident from "../../../Components/AnomaliesPerIncident/AnomaliesPerIncident.jsx";
import AvgResponsebyHourGraph from "../../../Components/AvgResponsebyHourGraph/AvgResponsebyHourGraph.jsx";
import AvgEmployeeIdealTime from "../../../Components/AvgEmployeeIdealTime/AvgEmployeeIdealTime.jsx";
import {
  useUserRole,
  useUserIsLoading,
} from "../../../services/user.service.jsx";
import { useSelector } from "react-redux";
import {
  handleFetchTimeSpentData,
  handleFetchAvgResponsebyHour,
  handleFetchModelAccuracy,
  handleFetchRealtimeAccuracy,
  handleFetchAreaList,
  handleFetchTotalCameras,
  handleFetchIncidentData,
  handleFetchIncidentResponseCount,
} from "../../../services/analysis.service.jsx";
import styles from "./analysis.module.css";

const StyledSelect = styled(Select)(() => ({
  "& .MuiSelect-select": {
    padding: "0px",
    fontSize: "16px",
    fontWeight: "500",
    lineHeight: "24px",
    display: "flex",
    alignItems: "center",
    minHeight: "24px",
    "&:focus": {
      backgroundColor: "transparent",
    },
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
  "& .MuiSvgIcon-root": {
    right: "4px",
    fontSize: "24px",
  },
}));

const Analysis = () => {
  const userRole = useUserRole();
  const isRoleLoading = useUserIsLoading();
  const [selectedArea, setSelectedArea] = useState("");
  const [filterData, setFilterData] = useState("Employee");
  const [loading, setLoading] = useState(true);
  const [days, setDays] = useState(7);

  const analysisState = useSelector((state) => state.analysis);
  const {
    modelAccuracy,
    realtimeAccuracy,
    areas,
    totalCameras,
    incidentData,
    incidentResponseCount,
    timeSpentData,
    error,
  } = analysisState;

  console.log(analysisState);

  useEffect(() => {
    const fetchData = async () => {
      await Promise.all([
        handleFetchRealtimeAccuracy(),
        handleFetchAreaList(),
        handleFetchTotalCameras(),
      ]);
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (isRoleLoading || !userRole || !days) return;

    setLoading(true);

    const fetchData = async () => {
      try {
        if (userRole === "admin") {
          handleFetchModelAccuracy();
        } else if (userRole === "workspace_admin" || userRole === "user") {
          handleFetchIncidentResponseCount(days);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [userRole, isRoleLoading, days]);

  useEffect(() => {
    handleFetchIncidentData(days);
  }, [days]);

  useEffect(() => {
    if (areas.length > 0) {
      setSelectedArea(areas[0]);
    }
  }, [areas]);

  useEffect(() => {
    if (userRole === "workspace_admin" || userRole === "user")
      handleFetchAvgResponsebyHour();
  }, [userRole]);

  useEffect(() => {
    if (selectedArea && userRole !== "workspace_admin") {
      setLoading(true);
      handleFetchTimeSpentData(selectedArea, filterData === "Employee")
        .then(() => setLoading(false))
        .catch(() => setLoading(false));
    }
  }, [selectedArea, filterData, userRole]);

  const handleAreaChange = (event) => {
    setSelectedArea(event.target.value);
  };

  const handleFilterChange = (event) => {
    setFilterData(event.target.value);
  };

  const filteredChartData = useMemo(
    () =>
      timeSpentData.filter(
        (item) => filterData === "Employee" || item.type === "Unknown"
      ),
    [timeSpentData, filterData]
  );

  const isUnknownFilter = filterData !== "Unknown";

  const inforCardData = useMemo(
    () => [
      {
        img: TotalCameraIMG,
        title: "Total Camera",
        count: totalCameras || "-",
      },
      {
        img: TotalIncidentIMG,
        title: "Total incident",
        count: incidentData.total || "-",
      },
      {
        img: CamerasOperationalIMG,
        title: "Cameras Operational",
        count: totalCameras || "-",
      },
      {
        img: CamerasNonOperationalIMG,
        title: "Cameras Non-Operational",
        count: 0,
      },
    ],
    [totalCameras, incidentData.total]
  );

  if (isRoleLoading) {
    return (
      <div className={styles.loaderContainer}>
        <CircularProgress />
      </div>
    );
  }

  if (!userRole) {
    return <div>Unable to load user role. Please try logging in again.</div>;
  }

  // if (error) {
  //   return <div>Error: {error}</div>;
  // }

  return (
    <div className={styles.AnalysisWrapper}>
      <h2>Analysis</h2>
      <div className={styles.InfoCardsContainer}>
        {inforCardData.map((card, index) => (
          <InfoCards
            key={index}
            ImgSrc={card.img}
            Title={card.title}
            Count={card.count}
          />
        ))}
      </div>
      <div className={styles.SystemAccuracy}>
        <h3>System Accuracy and Compliance</h3>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            {userRole === "admin" ? (
              <>
                <Grid item xs={4}>
                  <PieChartView
                    Title={"Model Accuracy"}
                    DropValue={"Monthly"}
                    PieValue={[
                      {
                        value: modelAccuracy,
                        color: "#007DD6",
                        label: "Model Accuracy",
                      },
                      {
                        value: 100 - modelAccuracy,
                        color: "#007DD633",
                        label: "Remaining data",
                      },
                    ]}
                    numberCount={1}
                  />
                </Grid>
                <Grid item xs={4}>
                  <PieChartView
                    Title={"Incidents Recorded"}
                    DropDown={true}
                    DropValue={"Weekly"}
                    PieValue={
                      incidentData.total === 0
                        ? [
                            { value: 0, color: "#FFCC00", label: "No data" },
                            {
                              value: 100,
                              color: "#FFCC00",
                              label: "Remaining data",
                            },
                          ]
                        : incidentData.percentages
                    }
                    numberCount={3}
                    setDays={setDays}
                  />
                </Grid>
                <Grid item xs={4}>
                  <PieChartView
                    Title={"Real-time accuracy"}
                    DropValue={"Monthly"}
                    PieValue={[
                      {
                        value: realtimeAccuracy,
                        color: "#6202FE",
                        label: "Real-time accuracy",
                      },
                      {
                        value: 100 - realtimeAccuracy,
                        color: "#0022D633",
                        label: "Remaining data",
                      },
                    ]}
                    numberCount={1}
                  />
                </Grid>
              </>
            ) : userRole === "workspace_admin" || userRole === "user" ? (
              <>
                <Grid item xs={4}>
                  <PieChartView
                    Title={"Incidents Recorded"}
                    DropDown={true}
                    DropValue={"Weekly"}
                    PieValue={
                      incidentData.total === 0
                        ? [
                            { value: 0, color: "#FFCC00", label: "No data" },
                            {
                              value: 100,
                              color: "#FFCC00",
                              label: "Remaining data",
                            },
                          ]
                        : incidentData.percentages
                    }
                    numberCount={3}
                    setDays={setDays}
                  />
                </Grid>
                <Grid item xs={4}>
                  <PieChartView
                    Title={"Incidents Response Status"}
                    DropDown={true}
                    DropValue={"Weekly"}
                    PieValue={[
                      {
                        value: incidentResponseCount.resolved,
                        color: "#4AB58E",
                        label: "Resolved Incident",
                      },
                      {
                        value: incidentResponseCount.unresolved,
                        color: "#EE4526",
                        label: "Active Incident",
                      },
                    ]}
                    numberCount={2}
                    setDays={setDays}
                  />
                </Grid>
                <Grid item xs={4}>
                  <PieChartView
                    Title={"Real-time accuracy"}
                    DropValue={"Monthly"}
                    PieValue={[
                      {
                        value: realtimeAccuracy,
                        color: "#6202FE",
                        label: "Real-time accuracy",
                      },
                      {
                        value: 100 - realtimeAccuracy,
                        color: "#0022D633",
                        label: "Remaining data",
                      },
                    ]}
                    numberCount={1}
                  />
                </Grid>
                <Grid item xs={12}>
                  <AvgResponsebyHourGraph />
                </Grid>
                <Grid item xs={7}>
                  <AnomaliesPerIncident />
                </Grid>
                <Grid item xs={5}>
                  <DetectionCountGraph />
                </Grid>
                {userRole === "workspace_admin" && userRole !== "user" && (
                  <Grid item xs={12}>
                    <AvgEmployeeIdealTime />
                  </Grid>
                )}
              </>
            ) : (
              <Grid item xs={8}>
                <p style={{ margin: "0px", fontSize: "16px" }}>
                  Role is undefined
                </p>
              </Grid>
            )}
          </Grid>
        </Box>
      </div>
      {userRole === "workspace_admin" || userRole === "user" ? (
        ""
      ) : (
        <>
          <div className={styles.SystemPerformance}>
            <h3>System Performance</h3>
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <PieChartView
                    Title={"Model Uptime"}
                    DropValue={"Monthly"}
                    PieValue={[
                      { value: 95, color: "#16458A", label: "Model Uptime" },
                      { value: 5, color: "#A9CCFF" },
                    ]}
                    numberCount={1}
                  />
                </Grid>
                <Grid item xs={8}>
                  <CpuUsageGraph
                    Title={"Resource Utilization (CPU, Memory)"}
                    userRole={userRole}
                    isRoleLoading={isRoleLoading}
                  />
                </Grid>
                <Grid item xs={6}>
                  <CameraFeedGraph Title={"Camera Feed Quality"} />
                </Grid>
                <Grid item xs={6}>
                  <ThermalGraph
                    Title={"Resource Utilization (CPU, GPU, Memory) Heat map"}
                  />
                </Grid>
              </Grid>
            </Box>
          </div>
          <div className={styles.SystemReliability}>
            <h3>Productivity and Efficiency</h3>
            <div className={styles.SystemReliabilityWrapper}>
              <div className={styles.SystemReliabilityHeader}>
                <h4>Employee Total Time Duration</h4>
                <div className={styles.chartFilter}>
                  <div className={styles.areaDropdown}>
                    <StyledSelect
                      value={selectedArea}
                      onChange={handleAreaChange}
                    >
                      {areas.map((area) => (
                        <MenuItem
                          key={area}
                          value={area}
                          sx={{ fontSize: "16px" }}
                        >
                          {area}
                        </MenuItem>
                      ))}
                    </StyledSelect>
                  </div>
                  <div className={styles.areaDropdown}>
                    <StyledSelect
                      value={filterData}
                      onChange={handleFilterChange}
                    >
                      <MenuItem
                        key={"Unknown"}
                        value={"Unknown"}
                        sx={{ fontSize: "16px" }}
                      >
                        Unknown
                      </MenuItem>
                      <MenuItem
                        key={"Employee"}
                        value={"Employee"}
                        sx={{ fontSize: "16px" }}
                      >
                        Employee
                      </MenuItem>
                    </StyledSelect>
                  </div>
                </div>
              </div>
              {loading ? (
                <p>Loading...</p>
              ) : (
                <BarChart
                  series={[
                    {
                      data: filteredChartData.map((item) =>
                        item.timeSpent.toFixed(2)
                      ),
                      color: "#5971F4",
                    },
                  ]}
                  slotProps={{
                    bar: { clipPath: `inset(0px round 12px 12px 0px 0px)` },
                    noDataOverlay: {
                      message: "No data to display in this Area",
                      sx: { fontSize: "20px" },
                    },
                  }}
                  height={400}
                  title={`Time Spent by ${
                    isUnknownFilter ? "Employees" : "Unknown"
                  } in Area ${selectedArea}`}
                  // barLabel="value"
                  xAxis={[
                    {
                      scaleType: "band",
                      data: filteredChartData.map((item) => item.name),
                    },
                  ]}
                  yAxis={[
                    {
                      label: "Time Spent (min)",
                      labelStyle: {
                        fill: "#667085",
                      },
                    },
                  ]}
                  grid={{
                    horizontal: true,
                    stroke: "#F2F4F7",
                  }}
                  leftAxis={{
                    disableLine: true,
                    disableTicks: true,
                  }}
                  bottomAxis={{
                    disableLine: true,
                    disableTicks: true,
                  }}
                  borderRadius={10}
                  sx={{
                    [`.${axisClasses.left} .${axisClasses.label}`]: {
                      transform: "translate(-10px, 0)",
                    },
                    [`.${axisClasses.root}`]: {
                      [`.${axisClasses.tickLabel}`]: {
                        fill: "#667085",
                      },
                    },
                  }}
                />
              )}
            </div>
            <Box sx={{ flexGrow: 1, marginTop: "20px" }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <AvgEmployeeIdealTime />
                </Grid>
              </Grid>
            </Box>
          </div>
        </>
      )}
    </div>
  );
};

export default React.memo(Analysis);
