import { store } from "../store";
import {
  fetchAlerts,
  fetchIncidentDetails,
  updateAnomaly,
} from "../actions/alert.action";

export const handleFetchAlerts = (page, rowsPerPage, selectedOption) => {
  return store.dispatch(fetchAlerts(page, rowsPerPage, selectedOption));
};

export const handleFetchIncidentDetails = (caseId, valueType) => {
  return store.dispatch(fetchIncidentDetails(caseId, valueType));
};

export const handleUpdateAnomaly = (id, valueType, isResolved) => {
  return store.dispatch(updateAnomaly(id, valueType, isResolved));
};
