import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import xenonstacklogo from "../../static/xenonstack-black-logo.svg";
import signUp from "../../static/SignUp.svg";
import dividertag from "../../static/divider-tag-login.png";
import googleicon from "../../static/google-icon.png";
import microsofticon from "../../static/microsoft-icon.png";
import "../Authentication/Signup.css";
import "../../common-files/primary-new.css";
import "../../common-files/grid-new.css";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Link } from "react-router-dom";
import { BASE_API_V1 } from "../../utils/config.js";

function Signup() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState({});
  const [passwordCriteria, setPasswordCriteria] = useState({
    length: false,
    uppercase: false,
    lowercase: false,
    digit: false,
    specialChar: false,
  });
  const [isPasswordFocused, setIsPasswordFocused] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const validatePassword = (password) => {
    const criteria = {
      length: password.length >= 8,
      uppercase: /[A-Z]/.test(password),
      lowercase: /[a-z]/.test(password),
      digit: /[0-9]/.test(password),
      specialChar: /[!@#$%^&*(),.?":{}|<>]/.test(password),
    };
    setPasswordCriteria(criteria);
    return Object.values(criteria).every(Boolean);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let processedValue = value;

    // if (name === "name") {
    //   processedValue = value.trim();
    // } else
    if (name === "email") {
      processedValue = value.toLowerCase();
    }

    setFormData({ ...formData, [name]: processedValue });

    setErrors({ ...errors, [name]: "" });

    if (name === "password") {
      validatePassword(value);
    }
  };

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.name.trim()) {
      newErrors.name = "Full name is required";
    } else if (!/^[a-zA-Z\s'-]+$/.test(formData.name.trim())) {
      newErrors.name =
        "Full name should only contain letters, spaces, hyphens, and apostrophes";
    }
    if (!formData.email.trim()) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Email is invalid";
    }
    if (!formData.password) {
      newErrors.password = "Password is required";
    } else if (!validatePassword(formData.password)) {
      newErrors.password = "Password does not meet the criteria";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSignup = async () => {
    if (!validateForm()) return;

    try {
      const response = await axios.post(`${BASE_API_V1}/signup`, {
        ...formData,
        name: formData.name.trim(),
        email: formData.email.toLowerCase(),
      });

      const message = response.data.message || "Signup successful!";
      toast.success(message);
      localStorage.setItem("signupEmail", formData.email.toLowerCase());
      localStorage.setItem("userName", formData.name.trim());
      setTimeout(() => {
        navigate("/");
      }, 5000);
    } catch (error) {
      handleAxiosError(error);
    }
  };

  const handleAxiosError = (error) => {
    if (error.response) {
      const { status, data } = error.response;
      if (status === 400 && data.detail === "Email already registered") {
        setErrors({ ...errors, email: "Email is already registered" });
        toast.error("Email is already registered");
      } else if (status === 400) {
        const errorMessage =
          data.detail || "Invalid input. Please check your details.";
        setErrors({ ...errors, general: errorMessage });
        toast.error(errorMessage);
      } else if (status === 500) {
        setErrors({
          ...errors,
          general: "Server error. Please try again later.",
        });
        toast.error("Server error. Please try again later.");
      } else {
        setErrors({
          ...errors,
          general: "An unexpected error occurred. Please try again.",
        });
        toast.error("An unexpected error occurred. Please try again.");
      }
    } else if (error.request) {
      setErrors({
        ...errors,
        general:
          "No response from server. Please check your internet connection.",
      });
      toast.error(
        "No response from server. Please check your internet connection."
      );
    } else {
      setErrors({ ...errors, general: "An error occurred. Please try again." });
      toast.error("An error occurred. Please try again.");
    }
  };

  return (
    <>
      <div>
        <Helmet>
          <title>Akira AI | Platform Signup</title>
        </Helmet>
      </div>
      <div className="login-page">
        <div className="left-section">
          <div className="signup-wrapper">
            <div className="login-logo">
              <img
                src={xenonstacklogo}
                alt="xenonsatck-ai-logo"
                draggable="false"
              />
            </div>
            <div className="signup-heading">
              <h2>Sign Up</h2>
              <p>
                Today is a new day. It's your day. You shape it. Sign in to
                start managing your projects.
              </p>
            </div>
            <div className="login-section">
              <div className="form-group">
                <label htmlFor="name">Full Name</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  placeholder="Please Enter your Full Name"
                  value={formData.name}
                  onChange={handleInputChange}
                  // onBlur={(e) =>
                  //   setFormData({ ...formData, name: e.target.value.trim() })
                  // }
                />
                {errors.name && <p className="error-message">{errors.name}</p>}
              </div>
              <div className="form-group">
                <label htmlFor="email">Email Id</label>
                <input
                  type="text"
                  id="email"
                  name="email"
                  placeholder="Please enter your Email Address"
                  value={formData.email}
                  onChange={handleInputChange}
                />
                {errors.email && (
                  <p className="error-message">{errors.email}</p>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="password">Password</label>
                <div className="password-input-wrapper">
                  <input
                    type={isPasswordVisible ? "text" : "password"}
                    id="password"
                    name="password"
                    placeholder="Please enter your Password"
                    value={formData.password}
                    onChange={handleInputChange}
                    onFocus={() => setIsPasswordFocused(true)}
                    onBlur={() => setIsPasswordFocused(false)}
                  />
                  <button
                    type="button"
                    className="toggle-password-visibility"
                    onClick={togglePasswordVisibility}
                  >
                    <i className="material-icons">
                      {isPasswordVisible ? <Visibility /> : <VisibilityOff />}
                    </i>
                  </button>
                </div>
                {errors.password && (
                  <p className="error-message">{errors.password}</p>
                )}
              </div>
              {isPasswordFocused && (
                <div className="password-criteria">
                  <p className={passwordCriteria.length ? "valid" : "invalid"}>
                    {passwordCriteria.length ? "✓" : "✗"} Minimum 8 characters
                  </p>
                  <p
                    className={passwordCriteria.uppercase ? "valid" : "invalid"}
                  >
                    {passwordCriteria.uppercase ? "✓" : "✗"} At least one
                    uppercase letter
                  </p>
                  <p
                    className={passwordCriteria.lowercase ? "valid" : "invalid"}
                  >
                    {passwordCriteria.lowercase ? "✓" : "✗"} At least one
                    lowercase letter
                  </p>
                  <p className={passwordCriteria.digit ? "valid" : "invalid"}>
                    {passwordCriteria.digit ? "✓" : "✗"} At least one digit
                  </p>
                  <p
                    className={
                      passwordCriteria.specialChar ? "valid" : "invalid"
                    }
                  >
                    {passwordCriteria.specialChar ? "✓" : "✗"} At least one
                    special character
                  </p>
                </div>
              )}
              {errors.general && (
                <p className="error-message">{errors.general}</p>
              )}
              <button className="login-button" onClick={handleSignup}>
                Create Account
              </button>
              <div className="divider-tag">
                <img src={dividertag} alt="divider-tag" />
              </div>
              <div className="signin-button-wrapper">
                <div className="signin-with-google-button">
                  <img src={googleicon} alt="google-icon" />
                  <p>Sign In Using Google</p>
                </div>
                <div className="signin-with-microsoft-button">
                  <img src={microsofticon} alt="microsoft-icon" />
                  <p>Sign In Using Microsoft</p>
                </div>
              </div>
            </div>
          </div>

          <div className="powered-by-text">
            <p>Already have an account?</p>
            <span>
              <Link to="/">Login</Link>
            </span>
          </div>
        </div>
        <div className="blue-section">
          <img src={signUp} alt="login-image" draggable="false" />
        </div>
      </div>
      <ToastContainer />
    </>
  );
}

export default Signup;
