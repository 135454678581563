export const FETCH_ALERTS_REQUEST = 'FETCH_ALERTS_REQUEST';
export const FETCH_ALERTS_SUCCESS = 'FETCH_ALERTS_SUCCESS';
export const FETCH_ALERTS_FAILURE = 'FETCH_ALERTS_FAILURE';

export const FETCH_INCIDENT_DETAILS_REQUEST = 'FETCH_INCIDENT_DETAILS_REQUEST';
export const FETCH_INCIDENT_DETAILS_SUCCESS = 'FETCH_INCIDENT_DETAILS_SUCCESS';
export const FETCH_INCIDENT_DETAILS_FAILURE = 'FETCH_INCIDENT_DETAILS_FAILURE';

export const UPDATE_ANOMALY_REQUEST = 'UPDATE_ANOMALY_REQUEST';
export const UPDATE_ANOMALY_SUCCESS = 'UPDATE_ANOMALY_SUCCESS';
export const UPDATE_ANOMALY_FAILURE = 'UPDATE_ANOMALY_FAILURE';