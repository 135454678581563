import {
  FETCH_ALERTS_REQUEST,
  FETCH_ALERTS_SUCCESS,
  FETCH_ALERTS_FAILURE,
  FETCH_INCIDENT_DETAILS_REQUEST,
  FETCH_INCIDENT_DETAILS_SUCCESS,
  FETCH_INCIDENT_DETAILS_FAILURE,
  UPDATE_ANOMALY_REQUEST,
  UPDATE_ANOMALY_SUCCESS,
  UPDATE_ANOMALY_FAILURE,
} from "../actionTypes/alert.type";

const initialState = {
  alerts: [],
  totalAlerts: 0,
  resolvedAlerts: 0,
  pendingAlerts: 0,
  incidentDetails: null,
  loading: false,
  error: null,
};

export default function alertReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_ALERTS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_ALERTS_SUCCESS:
      return {
        ...state,
        loading: false,
        alerts: action.payload.objects,
        totalAlerts: action.payload.total_incidents,
        resolvedAlerts: action.payload.issue_resolved,
        pendingAlerts: action.payload.issue_pending,
      };
    case FETCH_ALERTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case FETCH_INCIDENT_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_INCIDENT_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        incidentDetails: action.payload,
      };
    case FETCH_INCIDENT_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case UPDATE_ANOMALY_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case UPDATE_ANOMALY_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_ANOMALY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
