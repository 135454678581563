import * as types from "../actionTypes/analysis.type";

const initialState = {
  modelAccuracy: 0,
  realtimeAccuracy: 0,
  operationalCount: {},
  incidentResponseCount: { resolved: 0, unresolved: 0 },
  areas: [],
  timeSpentData: [],
  timeSpentEmployee: [],
  totalCameras: 0,
  incidentData: { total: 0, percentages: [] },
  avgResponseByHour: [],
  anomaliesData: [],
  detectionCount: [],
  avgIdeal: [],
  employeeActivity: [],
  employeeInteractions: {},
  loading: false,
  error: null,
};

export default function analysisReducer(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_MODEL_ACCURACY_REQUEST:
    case types.FETCH_REALTIME_ACCURACY_REQUEST:
    case types.FETCH_OPERATIONAL_COUNT_REQUEST:
    case types.FETCH_INCIDENT_RESPONSE_COUNT_REQUEST:
    case types.FETCH_AREA_LIST_REQUEST:
    case types.FETCH_TIME_SPENT_DATA_REQUEST:
    case types.FETCH_TIME_SPENT_EMPLOYEE_REQUEST:
    case types.FETCH_TOTAL_CAMERAS_REQUEST:
    case types.FETCH_INCIDENT_DATA_REQUEST:
    case types.FETCH_AVG_RESPONSE_BY_HOUR_REQUEST:
    case types.FETCH_ANOMALIES_DATA_REQUEST:
    case types.FETCH_DETECTION_COUNT_REQUEST:
    case types.FETCH_AVG_IDEAL_REQUEST:
    case types.FETCH_EMPLOYEE_ACTIVITY_ANALYSIS_REQUEST:
    case types.FETCH_EMPLOYEE_INTERACTIONS_REQUEST:
      // case types.FETCH_ANALYSIS_DATA_REQUEST:
      return { ...state, loading: true, error: null };

    case types.FETCH_MODEL_ACCURACY_SUCCESS:
      return { ...state, loading: false, modelAccuracy: action.payload };

    case types.FETCH_REALTIME_ACCURACY_SUCCESS:
      return { ...state, loading: false, realtimeAccuracy: action.payload };

    case types.FETCH_OPERATIONAL_COUNT_SUCCESS:
      return { ...state, loading: false, operationalCount: action.payload };

    case types.FETCH_INCIDENT_RESPONSE_COUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        incidentResponseCount: action.payload,
      };

    case types.FETCH_AREA_LIST_SUCCESS:
      return { ...state, loading: false, areas: action.payload };

    case types.FETCH_TIME_SPENT_DATA_SUCCESS:
      return { ...state, loading: false, timeSpentData: action.payload };

    case types.FETCH_TIME_SPENT_EMPLOYEE_SUCCESS:
      return { ...state, loading: false, timeSpentEmployee: action.payload };

    case types.FETCH_TOTAL_CAMERAS_SUCCESS:
      return { ...state, loading: false, totalCameras: action.payload };

    case types.FETCH_INCIDENT_DATA_SUCCESS:
      return { ...state, loading: false, incidentData: action.payload };

    case types.FETCH_AVG_RESPONSE_BY_HOUR_SUCCESS:
      return { ...state, loading: false, avgResponseByHour: action.payload };

    case types.FETCH_ANOMALIES_DATA_SUCCESS:
      return { ...state, loading: false, anomaliesData: action.payload };

    case types.FETCH_DETECTION_COUNT_SUCCESS:
      return { ...state, loading: false, detectionCount: action.payload };

    case types.FETCH_AVG_IDEAL_SUCCESS:
      return { ...state, loading: false, avgIdeal: action.payload };

    case types.FETCH_EMPLOYEE_ACTIVITY_ANALYSIS_SUCCESS:
      return { ...state, loading: false, employeeActivity: action.payload };

    case types.FETCH_EMPLOYEE_INTERACTIONS_SUCCESS:
      return { ...state, loading: false, employeeInteractions: action.payload };

    // case types.FETCH_ANALYSIS_DATA_SUCCESS:
    //   const [firstData, realtimeAccuracy, areas, totalCameras, incidentData] =
    //     action.payload;
    //   return {
    //     ...state,
    //     loading: false,
    //     realtimeAccuracy,
    //     areas,
    //     totalCameras,
    //     incidentData,
    //     ...(action.userRole === "admin"
    //       ? { modelAccuracy: firstData }
    //       : { incidentResponseCount: firstData }),
    //   };

    case types.FETCH_MODEL_ACCURACY_FAILURE:
    case types.FETCH_REALTIME_ACCURACY_FAILURE:
    case types.FETCH_OPERATIONAL_COUNT_FAILURE:
    case types.FETCH_INCIDENT_RESPONSE_COUNT_FAILURE:
    case types.FETCH_AREA_LIST_FAILURE:
    case types.FETCH_TIME_SPENT_DATA_FAILURE:
    case types.FETCH_TIME_SPENT_EMPLOYEE_FAILURE:
    case types.FETCH_TOTAL_CAMERAS_FAILURE:
    case types.FETCH_INCIDENT_DATA_FAILURE:
    case types.FETCH_AVG_RESPONSE_BY_HOUR_FAILURE:
    case types.FETCH_ANOMALIES_DATA_FAILURE:
    case types.FETCH_DETECTION_COUNT_FAILURE:
    case types.FETCH_AVG_IDEAL_FAILURE:
    case types.FETCH_EMPLOYEE_ACTIVITY_ANALYSIS_FAILURE:
    case types.FETCH_EMPLOYEE_INTERACTIONS_FAILURE:
      // case types.FETCH_ANALYSIS_DATA_FAILURE:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
}
