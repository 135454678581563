import * as types from "../actionTypes/user.types";

const initialState = {
  user: null,
  userRole: null,
  isLoading: false,
  error: null,
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case types.SET_USER:
      return { ...state, user: action.payload };
    case types.CLEAR_USER:
      return { ...state, user: null, userRole: null };
    case types.FETCH_USER_ROLE_REQUEST:
      return { ...state, isLoading: true, error: null };
    case types.FETCH_USER_ROLE_SUCCESS:
      return { ...state, isLoading: false, userRole: action.payload };
    case types.FETCH_USER_ROLE_FAILURE:
      return { ...state, isLoading: false, error: action.payload };
    default:
      return state;
  }
}
