import React, { useEffect, useState } from "react";
import { useNavigate, NavLink, Outlet } from "react-router-dom";
//importing images
import homeIcon from "../../../icon/Homepage.svg";
import alert from "../../../icon/Alert.svg";
import analysis from "../../../icon/Analysis.svg";
import xenonstacklogo from "../../../Images/xenonstack-website-new-logo-white.svg";
import userIMG from "../../../icon/icons8-user.png";
import logoutIMG from "../../../icon/log-out.svg";
import ChatRoundedIcon from "@mui/icons-material/ChatRounded";
//importing styling
import "./home.css";
//importing api functions
import {
  useUser,
  useUserRole,
  handleFetchUserRole,
  handleClearUser,
} from "../../../services/user.service";
import ChatBot from "../../../Components/ChatBot/ChatBot";

const HomeContent = () => {
  const navigate = useNavigate();
  const user = useUser();
  const userRole = useUserRole();

  const [chatBotOpen, setChatBotOpen] = useState(false);

  useEffect(() => {
    handleFetchUserRole();
  }, [user]);

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("isLoggedIn");
    localStorage.removeItem("email");
    localStorage.removeItem("name");
    handleClearUser();
    handleFetchUserRole();
    navigate("/");
  };

  return (
    <div className="home-wrapper">
      <div className="side-navbar">
        <div className="Xenonstack-log">
          <img src={xenonstacklogo} alt="Xenonstack logo" draggable="false" />
        </div>
        <ul className="nav-tabs">
          <li>
            <NavLink
              to="/home"
              className={({ isActive }) => (isActive ? "active" : "")}
            >
              <img src={homeIcon} alt="Home icon" draggable="false" /> Home
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/analysis"
              className={({ isActive }) => (isActive ? "active" : "")}
            >
              <img src={analysis} alt="Analysis icon" draggable="false" />{" "}
              Analysis
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/alert"
              className={({ isActive }) => (isActive ? "active" : "")}
            >
              <img src={alert} alt="Alert icon" draggable="false" /> Alerts
            </NavLink>
          </li>
        </ul>
        <div className="userRole">
          <p>{userRole?.toString().replace(/_/g, " ")}</p>
        </div>
        <div className="logout">
          <div>
            <img src={userIMG} alt="user" />
            <div>
              <p>{user?.name || localStorage.getItem("name")}</p>
              <p
                style={{
                  color: "#F2F4F7",
                  fontWeight: "400",
                  fontSize: "12px",
                }}
              >
                {user?.email || localStorage.getItem("email")}
              </p>
            </div>
          </div>
          <img
            src={logoutIMG}
            alt="logout"
            style={{ cursor: "pointer" }}
            onClick={handleLogout}
          />
        </div>
      </div>
      <div className="tab-content">
        <Outlet />
      </div>
      <div className="chatBot">
        <ChatRoundedIcon
          onClick={() => setChatBotOpen(true)}
          sx={{ fontSize: "var(--size-7xl)", color: "var(--clr-white)" }}
        />
      </div>

      <ChatBot open={chatBotOpen} onClose={() => setChatBotOpen(false)} />
    </div>
  );
};

const Home = () => {
  return <HomeContent />;
};

export default Home;
