import axios from "axios";
import { BASE_API_V1 } from "../utils/config";

const getAuthToken = () => localStorage.getItem("token");

export const fetchUserRoleApi = async () => {
  const token = getAuthToken();
  if (!token) {
    throw new Error("No auth token found");
  }
  const response = await axios.get(`${BASE_API_V1}/user-role`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};
