import {
  FETCH_ALERTS_REQUEST,
  FETCH_ALERTS_SUCCESS,
  FETCH_ALERTS_FAILURE,
  FETCH_INCIDENT_DETAILS_REQUEST,
  FETCH_INCIDENT_DETAILS_SUCCESS,
  FETCH_INCIDENT_DETAILS_FAILURE,
  UPDATE_ANOMALY_REQUEST,
  UPDATE_ANOMALY_SUCCESS,
  UPDATE_ANOMALY_FAILURE,
} from "../actionTypes/alert.type";
import Api from "../api/alert.api";

export const fetchAlerts =
  (page, rowsPerPage, selectedOption) => async (dispatch) => {
    dispatch({ type: FETCH_ALERTS_REQUEST });

    try {
      const response = await Api.fetchAlerts(page, rowsPerPage, selectedOption);
      dispatch({
        type: FETCH_ALERTS_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: FETCH_ALERTS_FAILURE,
        payload: error.message,
      });
    }
  };

export const fetchIncidentDetails = (caseId, valueType) => async (dispatch) => {
  dispatch({ type: FETCH_INCIDENT_DETAILS_REQUEST });

  try {
    const response = await Api.fetchIncidentDetails(caseId, valueType);
    dispatch({
      type: FETCH_INCIDENT_DETAILS_SUCCESS,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: FETCH_INCIDENT_DETAILS_FAILURE,
      payload: error.message,
    });
  }
};

export const updateAnomaly =
  (id, valueType, isResolved) => async (dispatch) => {
    dispatch({ type: UPDATE_ANOMALY_REQUEST });

    try {
      const response = await Api.updateAnomaly(id, valueType, isResolved);
      dispatch({
        type: UPDATE_ANOMALY_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: UPDATE_ANOMALY_FAILURE,
        payload: error.message,
      });
    }
  };
