export const FETCH_MODEL_ACCURACY_REQUEST = "FETCH_MODEL_ACCURACY_REQUEST";
export const FETCH_MODEL_ACCURACY_SUCCESS = "FETCH_MODEL_ACCURACY_SUCCESS";
export const FETCH_MODEL_ACCURACY_FAILURE = "FETCH_MODEL_ACCURACY_FAILURE";

export const FETCH_REALTIME_ACCURACY_REQUEST =
  "FETCH_REALTIME_ACCURACY_REQUEST";
export const FETCH_REALTIME_ACCURACY_SUCCESS =
  "FETCH_REALTIME_ACCURACY_SUCCESS";
export const FETCH_REALTIME_ACCURACY_FAILURE =
  "FETCH_REALTIME_ACCURACY_FAILURE";

export const FETCH_OPERATIONAL_COUNT_REQUEST =
  "FETCH_OPERATIONAL_COUNT_REQUEST";
export const FETCH_OPERATIONAL_COUNT_SUCCESS =
  "FETCH_OPERATIONAL_COUNT_SUCCESS";
export const FETCH_OPERATIONAL_COUNT_FAILURE =
  "FETCH_OPERATIONAL_COUNT_FAILURE";

export const FETCH_INCIDENT_RESPONSE_COUNT_REQUEST =
  "FETCH_INCIDENT_RESPONSE_COUNT_REQUEST";
export const FETCH_INCIDENT_RESPONSE_COUNT_SUCCESS =
  "FETCH_INCIDENT_RESPONSE_COUNT_SUCCESS";
export const FETCH_INCIDENT_RESPONSE_COUNT_FAILURE =
  "FETCH_INCIDENT_RESPONSE_COUNT_FAILURE";

export const FETCH_AREA_LIST_REQUEST = "FETCH_AREA_LIST_REQUEST";
export const FETCH_AREA_LIST_SUCCESS = "FETCH_AREA_LIST_SUCCESS";
export const FETCH_AREA_LIST_FAILURE = "FETCH_AREA_LIST_FAILURE";

export const FETCH_TIME_SPENT_DATA_REQUEST = "FETCH_TIME_SPENT_DATA_REQUEST";
export const FETCH_TIME_SPENT_DATA_SUCCESS = "FETCH_TIME_SPENT_DATA_SUCCESS";
export const FETCH_TIME_SPENT_DATA_FAILURE = "FETCH_TIME_SPENT_DATA_FAILURE";

export const FETCH_TIME_SPENT_EMPLOYEE_REQUEST =
  "FETCH_TIME_SPENT_EMPLOYEE_REQUEST";
export const FETCH_TIME_SPENT_EMPLOYEE_SUCCESS =
  "FETCH_TIME_SPENT_EMPLOYEE_SUCCESS";
export const FETCH_TIME_SPENT_EMPLOYEE_FAILURE =
  "FETCH_TIME_SPENT_EMPLOYEE_FAILURE";

export const FETCH_TOTAL_CAMERAS_REQUEST = "FETCH_TOTAL_CAMERAS_REQUEST";
export const FETCH_TOTAL_CAMERAS_SUCCESS = "FETCH_TOTAL_CAMERAS_SUCCESS";
export const FETCH_TOTAL_CAMERAS_FAILURE = "FETCH_TOTAL_CAMERAS_FAILURE";

export const FETCH_INCIDENT_DATA_REQUEST = "FETCH_INCIDENT_DATA_REQUEST";
export const FETCH_INCIDENT_DATA_SUCCESS = "FETCH_INCIDENT_DATA_SUCCESS";
export const FETCH_INCIDENT_DATA_FAILURE = "FETCH_INCIDENT_DATA_FAILURE";

export const FETCH_AVG_RESPONSE_BY_HOUR_REQUEST =
  "FETCH_AVG_RESPONSE_BY_HOUR_REQUEST";
export const FETCH_AVG_RESPONSE_BY_HOUR_SUCCESS =
  "FETCH_AVG_RESPONSE_BY_HOUR_SUCCESS";
export const FETCH_AVG_RESPONSE_BY_HOUR_FAILURE =
  "FETCH_AVG_RESPONSE_BY_HOUR_FAILURE";

export const FETCH_ANOMALIES_DATA_REQUEST = "FETCH_ANOMALIES_DATA_REQUEST";
export const FETCH_ANOMALIES_DATA_SUCCESS = "FETCH_ANOMALIES_DATA_SUCCESS";
export const FETCH_ANOMALIES_DATA_FAILURE = "FETCH_ANOMALIES_DATA_FAILURE";

export const FETCH_DETECTION_COUNT_REQUEST = "FETCH_DETECTION_COUNT_REQUEST";
export const FETCH_DETECTION_COUNT_SUCCESS = "FETCH_DETECTION_COUNT_SUCCESS";
export const FETCH_DETECTION_COUNT_FAILURE = "FETCH_DETECTION_COUNT_FAILURE";

export const FETCH_AVG_IDEAL_REQUEST = "FETCH_AVG_IDEAL_REQUEST";
export const FETCH_AVG_IDEAL_SUCCESS = "FETCH_AVG_IDEAL_SUCCESS";
export const FETCH_AVG_IDEAL_FAILURE = "FETCH_AVG_IDEAL_FAILURE";

export const FETCH_ANALYSIS_DATA_REQUEST = "FETCH_ANALYSIS_DATA_REQUEST";
export const FETCH_ANALYSIS_DATA_SUCCESS = "FETCH_ANALYSIS_DATA_SUCCESS";
export const FETCH_ANALYSIS_DATA_FAILURE = "FETCH_ANALYSIS_DATA_FAILURE";

export const FETCH_EMPLOYEE_ACTIVITY_ANALYSIS_REQUEST =
  "FETCH_EMPLOYEE_ACTIVITY_ANALYSIS_REQUEST";
export const FETCH_EMPLOYEE_ACTIVITY_ANALYSIS_SUCCESS =
  "FETCH_EMPLOYEE_ACTIVITY_ANALYSIS_SUCCESS";
export const FETCH_EMPLOYEE_ACTIVITY_ANALYSIS_FAILURE =
  "FETCH_EMPLOYEE_ACTIVITY_ANALYSIS_FAILURE";

export const FETCH_EMPLOYEE_INTERACTIONS_REQUEST =
  "FETCH_EMPLOYEE_INTERACTIONS_REQUEST";
export const FETCH_EMPLOYEE_INTERACTIONS_SUCCESS =
  "FETCH_EMPLOYEE_INTERACTIONS_SUCCESS";
export const FETCH_EMPLOYEE_INTERACTIONS_FAILURE =
  "FETCH_EMPLOYEE_INTERACTIONS_FAILURE";
