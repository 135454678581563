// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AvgResponsebyHourGraph_AvgResponsebyHourGraph__gGCcZ {
  width: 100%;
  background-color: #ffffff;
  border-radius: 22px;
  padding: 20px 30px;
  box-shadow: 0px 2px 12px 0px #536fcb24;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  height: 100%;
}

.AvgResponsebyHourGraph_AvgResponsebyHourGraph__gGCcZ h4 {
  font-size: 14px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: -0.02em;
  color: #000000;
  margin: 0;
}
`, "",{"version":3,"sources":["webpack://./src/Components/AvgResponsebyHourGraph/AvgResponsebyHourGraph.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,yBAAyB;EACzB,mBAAmB;EACnB,kBAAkB;EAClB,sCAAsC;EACtC,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,gBAAgB;EAChB,YAAY;AACd;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,uBAAuB;EACvB,cAAc;EACd,SAAS;AACX","sourcesContent":[".AvgResponsebyHourGraph {\n  width: 100%;\n  background-color: #ffffff;\n  border-radius: 22px;\n  padding: 20px 30px;\n  box-shadow: 0px 2px 12px 0px #536fcb24;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  overflow: hidden;\n  height: 100%;\n}\n\n.AvgResponsebyHourGraph h4 {\n  font-size: 14px;\n  font-weight: 700;\n  line-height: 28px;\n  letter-spacing: -0.02em;\n  color: #000000;\n  margin: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"AvgResponsebyHourGraph": `AvgResponsebyHourGraph_AvgResponsebyHourGraph__gGCcZ`
};
export default ___CSS_LOADER_EXPORT___;
